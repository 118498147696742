const EXCLUDED_CARD_PLACE_PROPS = ["pult"]; // срез лишних свойств для карточек

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  updateDevices({ dispatch, rootGetters }, devices) {
    if (!devices?.length) return;
    const deviceInfo = rootGetters["deviceFunctions/getDeviceInfo"];

    // console.log(devices);

    devices.forEach((device) => {
      const cardDevice = {};
      for (const key in device) {
        if (!EXCLUDED_CARD_PLACE_PROPS.find((prop) => prop === key)) {
          cardDevice[key] = device[key];
        }
      }

      dispatch("buildingDashboard/setPlaceData", cardDevice, { root: true });

      if (deviceInfo) {
        const isCurrentDeviceInfo = device.id === deviceInfo.id;
        if (isCurrentDeviceInfo) {
          dispatch("deviceFunctions/updateDeviceInfo", device, { root: true });
        }
      }
    });
  },

  updateGeoData({ dispatch, rootGetters }, geoData) {
    if (!geoData?.length) return;
    // TODO: вероятно нужно только для моб приложения
    // console.log("update geoData");
  },

  updateGroupPresets({ dispatch, commit }, groupPresets) {
    if (!groupPresets?.length) return;
    dispatch("buildingDashboard/updateGroupPresets", groupPresets, {
      root: true,
    });

    for (const updated in groupPresets) {
      commit("fastCommandsPage/EDIT_GROUP_PRESET", groupPresets[updated], {
        root: true,
      });
    }
  },

  updatePlaceSchedules({ dispatch }, placeSchedules) {
    if (!placeSchedules?.length) return;
    dispatch("buildingDashboard/updateSchedules", placeSchedules, {
      root: true,
    });
  },

  updatePresets({ dispatch, commit }, placePresets) {
    if (!placePresets?.length) return;
    dispatch("buildingDashboard/updatePresets", placePresets, { root: true });

    for (const updated in placePresets) {
      commit("fastCommandsPage/EDIT_PLACE_PRESET", placePresets[updated], {
        root: true,
      });
    }
  },

  updateProgresses({ commit, dispatch, rootGetters }, progresses) {
    if (!progresses?.length) return;

    const currentDevice = rootGetters["deviceFunctions/getDeviceInfo"];
    const currentDashboardPlaces =
      rootGetters["buildingDashboard/getBuildingSelectDashboard"].places;
    const currentDashboardGroups =
      rootGetters["buildingDashboard/getBuildingSelectDashboard"].groups;
    const currentDashboardPlacePresets =
      rootGetters["buildingDashboard/getBuildingSelectDashboard"].placePresets;
    const currentDashboardGroupPresets =
      rootGetters["buildingDashboard/getBuildingSelectDashboard"].groupPresets;
    const currentFCPagePlacePresets =
      rootGetters["fastCommandsPage/getPlacePresets"];
    const currentFCPageGroupPresets =
      rootGetters["fastCommandsPage/getGroupPresets"];

    progresses.forEach((progress) => {
      // <editor-fold desc="Pult/Device">
      if (currentDevice) {
        if (currentDevice.timer?.id === progress.id) {
          const timerProgress = {};
          Object.assign(timerProgress, currentDevice.timer.progress, progress);
          const timer = {};
          Object.assign(timer, currentDevice.timer, {
            progress: timerProgress,
          });
          dispatch(
            "deviceFunctions/updateDeviceInfo",
            {
              id: currentDevice.id,
              timer,
            },
            { root: true }
          );
        }

        if (currentDevice.progress?.id === progress.id) {
          const deviceProgress = {};
          Object.assign(deviceProgress, currentDevice.progress, progress);
          dispatch(
            "deviceFunctions/updateDeviceInfo",
            {
              id: currentDevice.id,
              progress: deviceProgress,
            },
            { root: true }
          );
        }
      }
      // </editor-fold>

      // <editor-fold desc="Places">
      if (currentDashboardPlaces) {
        const dashboardPlaceByTimer = currentDashboardPlaces.find(
          (place) => place.timer?.id === progress.id
        );
        if (dashboardPlaceByTimer) {
          const timerProgress = {};
          Object.assign(
            timerProgress,
            dashboardPlaceByTimer.timer.progress,
            progress
          );
          const timer = {};
          Object.assign(timer, dashboardPlaceByTimer.timer, {
            progress: timerProgress,
          });
          dispatch(
            "buildingDashboard/setPlaceData",
            {
              id: dashboardPlaceByTimer.id,
              timer,
            },
            { root: true }
          );
        }

        const dashboardPlaceByProgress = currentDashboardPlaces.find(
          (place) => place.progress?.id === progress.id
        );
        if (dashboardPlaceByProgress) {
          const deviceProgress = {};
          Object.assign(
            deviceProgress,
            dashboardPlaceByProgress.progress,
            progress
          );
          dispatch(
            "buildingDashboard/setPlaceData",
            {
              id: dashboardPlaceByProgress.id,
              progress: deviceProgress,
            },
            { root: true }
          );
        }
      }
      // </editor-fold>

      // <editor-fold desc="Groups">
      if (currentDashboardGroups) {
        let dashboardPlaceInGroupByTimer;
        currentDashboardGroups.forEach((group) => {
          const necessaryPlace = group.places.find(
            (place) => place.timer?.id === progress.id
          );
          if (necessaryPlace) {
            dashboardPlaceInGroupByTimer = necessaryPlace;
          }
        });
        if (dashboardPlaceInGroupByTimer) {
          const timerProgress = {};
          Object.assign(
            timerProgress,
            dashboardPlaceInGroupByTimer.timer.progress,
            progress
          );
          const timer = {};
          Object.assign(timer, dashboardPlaceInGroupByTimer.timer, {
            progress: timerProgress,
          });
          dispatch(
            "buildingDashboard/setPlaceData",
            {
              id: dashboardPlaceInGroupByTimer.id,
              timer,
            },
            { root: true }
          );
        }

        let dashboardPlaceInGroupByProgress;
        currentDashboardGroups.forEach((group) => {
          const necessaryPlace = group.places.find(
            (place) => place.progress?.id === progress.id
          );
          if (necessaryPlace) {
            dashboardPlaceInGroupByProgress = necessaryPlace;
          }
        });
        if (dashboardPlaceInGroupByProgress) {
          const deviceProgress = {};
          Object.assign(
            deviceProgress,
            dashboardPlaceInGroupByProgress.progress,
            progress
          );
          dispatch(
            "buildingDashboard/setPlaceData",
            {
              id: dashboardPlaceInGroupByProgress.id,
              progress: deviceProgress,
            },
            { root: true }
          );
        }
      }
      // </editor-fold>

      // <editor-fold desc="Place presets">
      if (currentDashboardPlacePresets) {
        const dashboardPlacePresetByTimer = currentDashboardPlacePresets.find(
          (placePreset) => placePreset.timer?.id === progress.id
        );
        if (dashboardPlacePresetByTimer) {
          const timerProgress = {};
          Object.assign(
            timerProgress,
            dashboardPlacePresetByTimer.timer.progress,
            progress
          );
          const timer = {};
          Object.assign(timer, dashboardPlacePresetByTimer.timer, {
            progress: timerProgress,
          });
          const preparedPlacePreset = {
            id: dashboardPlacePresetByTimer.id,
            timer,
          };
          dispatch("buildingDashboard/updatePresets", [preparedPlacePreset], {
            root: true,
          });
        }
      }

      if (currentFCPagePlacePresets) {
        const pageFCPlacePresetByTimer = currentFCPagePlacePresets.find(
          (placePreset) => placePreset.timer?.id === progress.id
        );
        if (pageFCPlacePresetByTimer) {
          const timerProgress = {};
          Object.assign(
            timerProgress,
            pageFCPlacePresetByTimer.timer.progress,
            progress
          );
          const timer = {};
          Object.assign(timer, pageFCPlacePresetByTimer.timer, {
            progress: timerProgress,
          });
          const preparedPlacePreset = {
            id: pageFCPlacePresetByTimer.id,
            timer,
          };
          commit("fastCommandsPage/EDIT_PLACE_PRESET", preparedPlacePreset, {
            root: true,
          });
        }
      }
      // </editor-fold>

      // <editor-fold desc="Group presets">
      if (currentDashboardGroupPresets) {
        const dashboardGroupPresetByTimer = currentDashboardGroupPresets.find(
          (placePreset) => placePreset.timer?.id === progress.id
        );
        if (dashboardGroupPresetByTimer) {
          const timerProgress = {};
          Object.assign(
            timerProgress,
            dashboardGroupPresetByTimer.timer.progress,
            progress
          );
          const timer = {};
          Object.assign(timer, dashboardGroupPresetByTimer.timer, {
            progress: timerProgress,
          });
          const preparedGroupPreset = {
            id: dashboardGroupPresetByTimer.id,
            timer,
          };
          dispatch(
            "buildingDashboard/updateGroupPresets",
            [preparedGroupPreset],
            { root: true }
          );
        }
      }

      if (currentFCPageGroupPresets) {
        const pageFCPlaceGroupByTimer = currentFCPageGroupPresets.find(
          (placePreset) => placePreset.timer?.id === progress.id
        );
        if (pageFCPlaceGroupByTimer) {
          const timerProgress = {};
          Object.assign(
            timerProgress,
            pageFCPlaceGroupByTimer.timer.progress,
            progress
          );
          const timer = {};
          Object.assign(timer, pageFCPlaceGroupByTimer.timer, {
            progress: timerProgress,
          });
          const preparedGroupPreset = {
            id: pageFCPlaceGroupByTimer.id,
            timer,
          };
          commit("fastCommandsPage/EDIT_GROUP_PRESET", preparedGroupPreset, {
            root: true,
          });
        }
      }
      // </editor-fold>
    });
  },

  updateSchedules({ dispatch }, schedules) {
    if (!schedules?.length) return;
    dispatch("buildingDashboard/updateSchedules", schedules, { root: true });
  },
};


import { mapGetters } from "vuex";
import { getSignDirection } from '@/utils/helpers';
import ControlPanelCircular from '@/components/control-panel/ControlPanelCircular.vue';
import ControlPanelIcon from '@/components/control-panel/ControlPanelIcon.vue';
import ControlPanelCard from '@/components/control-panel/ControlPanelCard.vue';
import ControlPanelLinear from '@/components/control-panel/ControlPanelLinear.vue';
import ControlPanelSwitch from "@/components/control-panel/ControlPanelSwitch.vue";
import ControlItemContextMenu from '@/components/contextMenu/controlItemContextMenu.vue';

export default {
  name: 'AddFastCommandControlPanel',
  components: {
    ControlItemContextMenu,
    ControlPanelCircular,
    ControlPanelIcon,
    ControlPanelCard,
    ControlPanelLinear,
  },
  props: {
    /* deviceInfo: {
      required: true,
      type: Object,
    }, */
    deviceId: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    setTimer: false,
    showSettings: false,
    deviceInfoSaveState: null,
    panelSettingsData: null,
    globalFunctionsLoading: false,
    cssVars: {},
    isHeater: false,
  }),
  computed: {
    ...mapGetters({
      deviceInfo: "deviceFunctions/getDeviceInfo",
    }),
    deviceAvailable() {
      return this.deviceConnected && this.deviceInfo?.state?.isOn;
    },
    curTempSign() {
      return getSignDirection(this.deviceInfo.curTemp);
    },
  },
  watch: {
    deviceInfo: {
      handler() {
        this.globalFunctionsLoading = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.cssVars = {
      '--primary': this.deviceInfo.theme.primary,
      '--background': this.deviceInfo.theme.background,
      '--gradient': `linear-gradient(90deg, ${this.deviceInfo.theme.gradient[0]} 0%, ${this.deviceInfo.theme.gradient[1]} 100%)`
    };
    this.isHeater = this.deviceInfo.deviceType === 'HEATER';
  },
  methods: {
    applyDeviceFunction(data) {
      this.globalFunctionsLoading = true;
      this.$emit('customChange', data);
    },
    toggleControlItemContextMenu(e) {
      this.$refs.controlItemContextMenu.toggle(e);
    },
  },
};


export default {
  name: 'ShowStateDetails',
  props: {
    stateDetails: {
      type: Object,
      required: true
    },
    iconSize: {
      type: String,
      default: '14'
    },
    breakLimit: {
      type: Number,
      default: 4
    }
  },
  data: () => ({
    overflow: 0,
  }),
  computed: {
    iconClass() {
      return `icon-${this.iconSize}`;
    },
    showState() {
      if (!Array.isArray(this.stateDetails)) return [];

      let overallLenth = this.stateDetails.reduce((acc, state) => {
        return acc + state.details.length
      }, 0);

      if (overallLenth < this.breakLimit) {
        return this.stateDetails;
      }

      let stopPoint = 0;
      let result = [];

      for (let i = 0; i < this.stateDetails.length; i += 1) {
        if (!this.stateDetails[i].details?.length) continue;

        if (this.calcLength(this.stateDetails[i].details) + stopPoint <= this.breakLimit) {
          stopPoint += this.calcLength(this.stateDetails[i].details);
          result.push({details: this.stateDetails[i].details});
        } else {
          if (this.breakLimit - stopPoint > 0) {
            result.push({details: this.stateDetails[i].details.slice(0, this.breakLimit - stopPoint)});
          }
          this.overflow = overallLenth - this.breakLimit;
          break;
        }
      }

      return result;

    }
  },
  methods: {
    calcLength(arr) {
      return arr.reduce((acc, element) => {
        return acc + (element.iconSvg ? 1 : 0) + (element.text ? 1 : 0)
      }, 0);
    },
  }
}


import { mapActions, mapGetters } from "vuex";
import mqtt from "mqtt";
import DefaultMessageModal from "@/components/modals/DefaultMessageModal.vue";
import AuthLogo from "~/assets/images/authLogo.svg";
import AuthFooter from "@/components/auth/AuthFooter.vue";
import ControlFunctionDetailModal from "@/components/modals/ControlFunctionDetailModal.vue";
import CancelScheduleModal from "@/components/modals/CancelScheduleModal.vue";
import ConflictResolveModal from "@/components/modals/ConflictResolveModal.vue";
import StartTimerModal from "@/components/modals/StartTimerModal.vue";
import Modal500 from "@/components/modals/Modal500.vue";
import ModalNoConnection from "@/components/modals/ModalNoConnection.vue";
import SmallScreen from "../components/SmallScreen.vue";
import SideMenu from "../components/Menus/SideMenu.vue";
import StartPage from "../components/start-page.vue";
import PeopleIcon from "../assets/icons/people.svg";
import ControlPanel from "../components/Menus/ControlPanel.vue";
import ObjectsPanel from "../components/Menus/ObjectsPanel.vue";
import HeaterAdditionalPanel from "@/components/Menus/HeaterAdditionalPanel";
import AdditionalModeModal from "../components/modals/AdditionalModeModal.vue";
import OptionInfoModal from "../components/modals/OptionInfoModal.vue";
import PaymentInfoModal from "../components/modals/PaymentInfoModal.vue";
import GoToAppModal from "../components/modals/GoToAppModal.vue";
import InputModal from "../components/modals/InputModal.vue";
import EditGroupModal from "../components/modals/EditGroupModal.vue";
import FastCommandsPanel from "../components/Menus/FastCommandsPanel.vue";
import DeleteModal from "../components/modals/DeleteModal.vue";
import ServiceSuccessModal from "../components/modals/serviceSuccessModal.vue";
import AddFastCommandModal from "../components/modals/AddFastCommandModal.vue";
import AddCommandModal from "../components/modals/AddCommandModal.vue";
import FastCommandModal from "../components/modals/FastCommandModal.vue";
import "long-press-event";
import ScheduleMenu from "../components/Menus/ScheduleMenu.vue";
import CreateEditSchedule from "../components/modals/CreateEditSchedule.vue";
import NameScheduleModal from "../components/modals/NameScheduleModal.vue";
import PaymentModal from "../components/modals/PaymentModal";
import ClimateOnlineModal from "../components/modals/ClimateOnlineModal.vue";
import ClimateOnlineSummaryModal from "../components/modals/ClimateOnlineSummaryModal.vue";
import RecommendationModal from "../components/modals/RecommendationModal.vue";
import DeviceErrorModal from "../components/modals/DeviceErrorModal.vue";
import ComfortSleepModal from "../components/modals/ComfortSleepModal";
import IndicatorModal from "../components/modals/IndicatorModal";
import CleanerLegendModal from "../components/modals/CleanerLegendModal";
import YandexCaptcha from "@/components/YandexCaptcha.vue";

export default {
  name: "Dashboard",
  components: {
    NameScheduleModal,
    CreateEditSchedule,
    ScheduleMenu,
    FastCommandModal,
    AddCommandModal,
    Modal500,
    ModalNoConnection,
    StartTimerModal,
    AddFastCommandModal,
    ServiceSuccessModal,
    SmallScreen,
    CancelScheduleModal,
    ConflictResolveModal,
    AuthLogo,
    DeleteModal,
    DefaultMessageModal,
    FastCommandsPanel,
    EditGroupModal,
    ControlPanel,
    ObjectsPanel,
    HeaterAdditionalPanel,
    InputModal,
    GoToAppModal,
    PaymentInfoModal,
    OptionInfoModal,
    AdditionalModeModal,
    ControlFunctionDetailModal,
    SideMenu,
    PeopleIcon,
    StartPage,
    AuthFooter,
    PaymentModal,
    ClimateOnlineModal,
    ClimateOnlineSummaryModal,
    RecommendationModal,
    DeviceErrorModal,
    ComfortSleepModal,
    IndicatorModal,
    CleanerLegendModal,
    YandexCaptcha
  },
  data: () => ({
    innerWidth: null,
    isScroll: 0,
    showContent: false,
    animationLayout: false,
    mqttClient: null,
  }),
  async fetch() {
    await this.fetchBuildingList();
    this.showContent = true;
    this.$nextTick(() => {
      const dashboardShell = document.querySelector(".dashboard__shell");
      if (dashboardShell) {
        dashboardShell.addEventListener("scroll", () => {
          this.$bus.$emit("openContextMenu");
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      menuActive: "slideMenu/getMenuState",
      panelActive: "system/getPanelState",
      objectsPanelActive: "system/getObjectsPanelActive",
      additionalPanelActive: "system/getAdditionalPanelActive",
      timerPanelActive: "system/getObjectsPanelActive",
      fastCommandPanel: "system/getFastCommandsPanel",
      modalOpened: "system/getShowModal",
      buildingsList: "buildings/getBuildingsList",
      buildingId: "buildings/getBuildingSelectId",
      scheduleMenuActive: "system/getScheduleMenuActive",
    }),
    currentPage() {
      try {
        return this.$route.path.split("/").filter((i) => i)[0];
      } catch {
        return "";
      }
    },
    isSmallScreenVisible() {
      if (this.$route.path.includes("payments")) {
        return false;
      }

      return this.innerWidth && this.innerWidth < 768;
    },
  },
  watch: {
    buildingId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          this.animationLayout = true;
          setTimeout(() => {
            this.animationLayout = false;
          }, 600);
        }
      },
    },
    scheduleMenuActive: {
      handler(newValue) {
        if (newValue === true) {
          window.addEventListener("keydown", this.closeSchedulePanelListener);
        } else if (newValue === false) {
          window.removeEventListener(
            "keydown",
            this.closeSchedulePanelListener
          );
        }
      },
    },
    fastCommandPanel: {
      handler(newValue) {
        if (newValue === true) {
          window.addEventListener(
            "keydown",
            this.closeFastCommandPanelListener
          );
        } else if (newValue === false) {
          window.removeEventListener(
            "keydown",
            this.closeFastCommandPanelListener
          );
        }
      },
    },
    modalOpened: {
      handler(newValue) {
        if (newValue === true && this.panelActive) {
          window.removeEventListener("keydown", this.closePanelListener);
        } else if (newValue === false && this.panelActive) {
          window.addEventListener("keydown", this.closePanelListener);
        }

        if (newValue === true && this.objectsPanelActive) {
          window.removeEventListener("keydown", this.closeObjectsPanelListener);
        } else if (newValue === false && this.objectsPanelActive) {
          window.addEventListener("keydown", this.closeObjectsPanelListener);
        }

        if (newValue === true && this.additionalPanelActive) {
          window.removeEventListener("keydown", this.closeAdditionalPanelListener);
        } else if (newValue === false && this.additionalPanelActive) {
          window.addEventListener("keydown", this.closeAdditionalPanelListener);
        }
      },
    },
    panelActive: {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          if (newValue && window.innerWidth < 1440) {
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "auto";
          }
          if (newValue) {
            window.addEventListener("keydown", this.closePanelListener);
          } else {
            window.removeEventListener("keydown", this.closePanelListener);
          }
        }
      },
      immediate: true,
    },
    additionalPanelActive: {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          if (newValue && window.innerWidth < 1440) {
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "auto";
          }
          if (newValue) {
            window.addEventListener("keydown", this.closeAdditionalPanelListener);
          } else {
            window.removeEventListener("keydown", this.closeAdditionalPanelListener);
          }
        }
      },
      immediate: true,
    },
    objectsPanelActive: {
      handler(newValue, oldValue) {
        if (oldValue !== newValue) {
          if (newValue && window.innerWidth < 1440) {
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "auto";
          }
          if (newValue) {
            window.addEventListener("keydown", this.closeObjectsPanelListener);
          } else {
            window.removeEventListener(
              "keydown",
              this.closeObjectsPanelListener
            );
          }
        }
      },
      immediate: true,
    },
    menuActive: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue) {
            document.body.style.overflow = "hidden";
          } else if (oldValue) {
            document.body.style.overflow = "auto";
          }
        }
      },
    },
  },
  created() {
    const screenWidth = document.body.clientWidth;
    this.setScreen(screenWidth);
  },
  mounted() {
    this.innerWidth = window.innerWidth;
    window.addEventListener("resize", this.resizeHandler);
    window.addEventListener("offline", this.offlineHandler);
    window.addEventListener("online", this.onlineHandler);

    this.connectWSS();
  },
  beforeDestroy() {
    // beforeUnmount - не срабатывает
    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("offline", this.offlineHandler);
    window.removeEventListener("online", this.onlineHandler);

    this.disconnectWSS();
  },
  methods: {
    ...mapActions({
      setScreen: "system/setScreenWidth",
      fetchBuildingList: "buildings/fetchBuildingList",
      togglePanelActive: "system/togglePanelActive",
      toggleAdditionalPanelActive: "system/toggleAdditionalPanelActive",
      setObjectPanel: "system/setObjectPanel",
      toggleObjectsPanel: "system/setObjectPanel",
      toggleFastCommandPanel: "system/toggleFastCommandsPanel",
    }),
    closeObjectsPanelListener(e) {
      if (e.keyCode === 27 && !this.modalOpened) {
        this.toggleObjectsPanel(false);
      }
    },
    closeSchedulePanelListener(e) {
      if (e.keyCode === 27 && !this.modalOpened) {
        this.$store.commit("system/TOGGLE_SCHEDULE_PANEL", false);
      }
    },
    closePanelListener(e) {
      if (e.keyCode === 27 && !this.modalOpened) {
        this.togglePanelActive(false);
        this.toggleAdditionalPanelActive(false);
        this.setObjectPanel(false);
        this.toggleObjectsPanel(false);
      }
    },
    closeAdditionalPanelListener(e) {
      if (e.keyCode === 27 && !this.modalOpened) {
        this.toggleAdditionalPanelActive(false);
      }
    },
    closeFastCommandPanelListener(e) {
      if (e.keyCode === 27 && !this.modalOpened) {
        this.toggleFastCommandPanel(false);
      }
    },
    resizeHandler() {
      this.innerWidth = window.innerWidth;
    },
    offlineHandler() {
      this.$modal.show("noConnection");
    },
    onlineHandler() {
      this.$modal.hide("noConnection");
    },
    connectWSS() {
      // TODO: провека на logged не проходит, т.к. на момент mounted $auth.user может быть еще не установлен
      // console.log(this.$auth.user);
      // if (!this.$auth.user.loggedIn) {
      //   console.error('[WSS] Пользователь не авторизован');
      //   return;
      // }

      const { mqttUser } = this.$auth.user;

      this.mqttClient = mqtt.connect(process.env.MQTT_URL, {
        username: mqttUser.username,
        password: mqttUser.password,
      });

      this.mqttClient.on("connect", () => {
        this.mqttClient.subscribe(
          `user/${this.$auth.user.id}/notification`,
          (error) => {
            if (!error) {
              console.log("subscribed");
            }
          }
        );
      });

      this.mqttClient.on("message", (topic, message) => {
        const messageJSON = JSON.parse(message);
        console.log(new Date().toLocaleTimeString(), "mqtt", messageJSON);

        this.$store.dispatch("mqtt/updateDevices", messageJSON.devices);
        this.$store.dispatch("mqtt/updateGeoData", messageJSON.geoData);
        this.$store.dispatch(
          "mqtt/updateGroupPresets",
          messageJSON.groupPresets
        );
        this.$store.dispatch(
          "mqtt/updatePlaceSchedules",
          messageJSON.placeSchedules
        );
        this.$store.dispatch("mqtt/updatePresets", messageJSON.presets);
        this.$store.dispatch("mqtt/updateProgresses", messageJSON.progresses);
        this.$store.dispatch("mqtt/updateSchedules", messageJSON.schedules);
      });
    },
    disconnectWSS() {
      this.mqttClient.end();
    },
  },
};

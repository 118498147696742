import {
  SET_MODAL_ADDITIONAL_MODE_ICON,
  SET_MODAL_ADDITIONAL_MODE_TEXT,
  SET_MODAL_ADDITIONAL_MODE_TITLE,
} from '@/utils/types';

export const state = () => ({
  iconSvg: '',
  title: '',
  text: '',
});

export const getters = {
  getIcon: ({ iconSvg }) => iconSvg,
  getTitle: ({ title }) => title,
  getText: ({ text }) => text,
};

export const mutations = {
  [SET_MODAL_ADDITIONAL_MODE_ICON](state, value) {
    state.iconSvg = value;
  },
  [SET_MODAL_ADDITIONAL_MODE_TITLE](state, title) {
    state.title = title;
  },
  [SET_MODAL_ADDITIONAL_MODE_TEXT](state, text) {
    state.text = text;
  },
};

export const actions = {
  setModalData({ commit }, data) {
    data.icon !== null && data.icon !== 'undefined' && commit(SET_MODAL_ADDITIONAL_MODE_ICON, data.icon);
    data.iconSvg && commit(SET_MODAL_ADDITIONAL_MODE_ICON, data.iconSvg);
    data.title && commit(SET_MODAL_ADDITIONAL_MODE_TITLE, data.title);
    data.text && commit(SET_MODAL_ADDITIONAL_MODE_TEXT, data.text);
  },
};


import ToggleButton from '@/components/Buttons/ToggleButton';

export default {
  name: 'ControlPanelSwitch',
  components: { ToggleButton },
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    functionBlockId: {
      type: Number,
      required: true,
    },
    functionItem: {
      type: Object,
      required: true,
    },
    needForUpdate: {
      type: Boolean,
      default: false,
    },
    isHeater: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOn: false,
  }),
  computed: {
    blocked() {
      return this.functionItem.state.blocked || !this.deviceConnected;
    },
    controllable() {
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    active() {
      return this.functionItem.state.isOn;
    },
    controllableToggle() {
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    onOffState() {
      return this.functionItem.state.isOn;
    },
  },
  watch: {
    functionItem: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.prepareData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    prepareData() {
      const { valueRange } = this.functionItem.state;
      this.minTemp = valueRange[0];
      this.maxTemp = valueRange[valueRange.length - 1];
      this.temperature = this.functionItem.state.value;
    },

    changeOnOffState(value) {
      const requestValue = {
        functionId: this.functionItem.id,
        isOn: value,
        parameters: null,
      };
      this.$emit('controlStateChanged', requestValue);
    },
  },
}

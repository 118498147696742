
import lottie from 'vue-lottie/src/lottie.vue';
import * as whiteAnimationData from 'static/DC-spinner-functions-white.json';
import * as orangeAnimationData from 'static/DC-spinner-functions-orange.json';
import * as commonAnimationData from 'static/DC-spinner-functions.json';

export default {
  name: 'LoadingSpinnerFunction',
  components: {
    lottie,
  },
  props: {
    playing: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isOrange: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    anim: null,
    commonLottieOptions: {
      animationData: commonAnimationData.default,
      autoplay: false,
      type: 'commonLottieOptions'
    },
    whiteLottieOptions: {
      animationData: whiteAnimationData.default,
      autoplay: false,
      type: 'whiteLottieOptions'
    },
    orangeLottieOptions: {
      animationData: orangeAnimationData.default,
      autoplay: false,
      type: 'orangeLottieOptions'
    },
  }),
  computed: {
    lottieOptions() {
      if (this.isWhite) {
        return this.whiteLottieOptions;
      } else if (this.isOrange) {
        return this.orangeLottieOptions;
      }
      return this.commonLottieOptions;
    }
  },
  watch: {
    playing() {
      if (this.playing) {
        this.anim.play();
      } else {
        this.anim.stop();
      }
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
  },
};



import { mapActions, mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import Calendar from 'assets/icons/calendar.svg';
import ScheduleIcon from '@/assets/icons/14x14/schedule_new.svg';
import SubscribeCardRoomState from './CardRoomState/SubscribeCardRoomState.vue';
import DisconnectCardRoomState from './CardRoomState/DisconnectCardRoomState.vue';
import ConnectedCardRoomState from './CardRoomState/ConnectedCardRoomState.vue';
import OffCardRoomState from './CardRoomState/OffCardRoomState.vue';
import FixCard from '../../assets/icons/fixcard.svg';
import AppCheckbox from '../checks/AppCheckbox.vue';
import PlaceStatus from '../class/PlaceStatus';
import { isTablet } from '../../utils/helpers';

export default {
  name: 'CardRoom',
  components: {
    AppCheckbox,
    OffCardRoomState,
    ConnectedCardRoomState,
    DisconnectCardRoomState,
    SubscribeCardRoomState,
    FixCard,
    Calendar,
    ScheduleIcon,
  },
  mixins: [clickaway],
  props: {
    place: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'standard',
    },
    hidePin: {
      type: Boolean,
      default: false,
    },
    fromGroup: {
      type: Boolean,
      default: false,
    },
    animation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['contextMenuShow', 'dragstart', 'dragend', 'drop', 'dragleave', 'dragover'],
  data: () => ({
    blocked: false,
    showContext: true,
    hasTouch: null,
    isHeater: false,
  }),
  computed: {
    ...mapGetters({
      panelDeviceId: 'system/getPanelDeviceId',
    }),
    placeStatus() {
      return new PlaceStatus(this.place);
    },
    opened() {
      return this.place?.id === this.panelDeviceId;
    },
    cssVars() {
      return {
        '--primary': this.place.theme.primary,
        '--background': this.place.theme.background,
        '--gradient': `linear-gradient(to right, ${this.place.theme.gradient[0]} 0%, ${this.place.theme.gradient[1]} 100%)`
      };
    }
  },
  beforeMount() {
    this.isHeater = this.place.deviceType === 'HEATER';
  },
  mounted() {
    setTimeout(() => {
      if (this.animation) {
        const { cardRoom } = this.$refs;
        cardRoom.style.opacity = 1;
      }
    }, 300);
    this.$refs.cardRoom.addEventListener('long-press', (e) => {
      this.showContext && this.$emit('contextMenuShow', [e, this.place]);
    });
    this.$nextTick(() => {
      this.hasTouch = isTablet();
    });
  },
  beforeDestroy() {
    this.$refs.cardRoom.removeEventListener('long-press', (e) => {
      this.showContext && this.$emit('contextMenuShow', [e, this.place]);
    });
  },
  methods: {
    ...mapActions({
      openPanel: 'system/togglePanelActive',
      setPanelDeviceId: 'system/setPanelDevice',
      setObjectPanel: 'system/setObjectPanel',
      togglePower: 'buildingDashboard/setDevicePowerStatus',
      togglePanelFromGroup: 'system/togglePanelFromGroup',
      applyDeviceFunction: 'deviceFunctions/applyDeviceFunction',
      fastCommandPanel: 'system/toggleFastCommandsPanel',
    }),
    dragStart(e) {
      this.showContext = !!this.hasTouch;
      this.$emit('dragstart', e);
    },
    dragEnd(e) {
      this.showContext = true;
      this.$emit('dragend', e);
    },
    dragOver(e) {
      e.preventDefault();
      const { cardRoom } = this.$refs;
      cardRoom.classList.add('card-room--drag-over');
      this.$emit('dragover', e);
    },
    drop(e) {
      this.showContext = true;
      e.preventDefault();
      this.$refs.cardRoom.classList.remove('card-room--drag-over');
      this.$emit('drop', e);
    },
    dragLeave(e) {
      e.preventDefault();
      this.$refs.cardRoom.classList.remove('card-room--drag-over');
      this.$emit('dragleave', e);
    },
    clickHandler(e) {
      let isItCheckBox = false;
      const path = e.path || (e.composedPath && e.composedPath());
      path.forEach((item) => {
        if (item.className === 'card-room__toggle') {
          isItCheckBox = true;
        }
      });
      if (!isItCheckBox) {
        this.togglePanelFromGroup(this.fromGroup); // должно быть строго до вызова setObjectPanel
        this.setPanelDeviceId(this.place.id);
        this.setObjectPanel(false);
        this.$store.commit('system/TOGGLE_SCHEDULE_PANEL', false);
        this.fastCommandPanel(false);
        this.openPanel(true);
      }
    },
    async togglePowerStatus() {
      if (this.place.status !== 'disconnected' && !this.blocked) {
        this.blocked = true;
        try {
          await this.togglePower(this.place);
        } catch (e) {
          console.log(e);
        }
        this.blocked = false;
      }
    },
  },
};


import TimerIcon from '../../assets/icons/24x24/timer_new.svg';
import ScheduleIcon from '../../assets/icons/24x24/schedule_new.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'ScheduleButton',
  components: { TimerIcon, ScheduleIcon },
  props: {
    type: {
      type: String,
      default: 'timer',
    },
    state: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: '',
    },
    indicator: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
  },
};


import { mapGetters } from 'vuex';
// import lottie from 'vue-lottie/src/lottie.vue';
import InputRange from '@/components/Inputs/InputRange';
import ButtonCount from '@/components/Buttons/ButtonCount';
import ToggleButton from '@/components/Buttons/ToggleButton';
import { debounce } from '@/utils/helpers';
// import * as badQuality from '@/static/bad-quality.json';
// import * as mediumQuality from '@/static/medium-quality.json';
// import * as goodQuality from '@/static/good-quality.json';

export default {
  name: 'ControlPanelLottie',
  components: { ButtonCount, InputRange, ToggleButton },
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    functionBlockId: {
      type: Number,
      required: true,
    },
    functionItem: {
      type: Object,
      required: true,
    },
    needForUpdate: {
      type: Boolean,
      default: false,
    },
    animations: {
      type: Array,
      default: () => {},
    },
  },
  data: () => ({
    minTemp: 0,
    maxTemp: 40,
    temperature: 0,
    anim: null,
    // animationData: {
    //   bad: badQuality.default,
    //   medium: mediumQuality.default,
    //   good: goodQuality.default,
    // },
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    tempUnitSymbol() {
      if (this.functionItem && this.functionItem.uiInfo && this.functionItem.uiInfo.units) {
        return this.functionItem.uiInfo.units.replace('℃', '°С');
      }
      return '';
    },
    blocked() {
      return this.functionItem.state.blocked || !this.deviceConnected;
    },
    controllable() {
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    active() {
      return this.functionItem.state.isOn;
    },
    controllableToggle() {
      return this.functionItem.linkedFunction?.state.controllable && this.deviceConnected;
    },
    onOffState() {
      return this.functionItem.linkedFunction?.state.isOn;
    },
    quality() {
      if (!this.functionItem.state.isOn) return null;
      if (!this.functionItem.uiInfo.colorScheme) return null;

      switch (this.functionItem.uiInfo.colorScheme) {
        case 'SCHEME_1':
          return {
            text: this.getTranslations['Отличное качество'] || 'Отличное качество',
            bg: 'linear-gradient(90deg, #5EA9FF 0%, rgba(94, 169, 255, 0.5) 100%)',
          };
        case 'SCHEME_2':
          return {
            text: this.getTranslations['Хорошее качество'] || 'Хорошее качество',
            bg: 'linear-gradient(90deg, #19D9B7 0%, #FFA875 100%)',
          };
        case 'SCHEME_3':
          return {
            text: this.getTranslations['Удовлетворительно'] || 'Удовлетворительно',
            bg: 'linear-gradient(90deg, #859BFF 10.49%, #FFBAAD 100%)',
          };
        case 'SCHEME_4':
          return {
            text: this.getTranslations['Воздух загрязнён'] || 'Воздух загрязнён',
            bg: 'linear-gradient(90deg, #FF694D 0%, #FFC2A0 100%)',
          };
        case 'SCHEME_5':
          return {
            text: this.getTranslations['Высокое загрязнение'] || 'Высокое загрязнение',
            bg: 'linear-gradient(90deg, #FC4242 0%, rgba(252, 66, 66, 0.5) 80.73%)',
          };
        default:
          return null;
      }
    },
    isInactive() {
      if (!this.functionItem.state.controllable/*  || !this.deviceConnected */) return true;

      return false;
    },
    currentAnimation() {
      if (this.functionItem.state.isOn && this.functionItem.uiInfo.colorScheme) {
        return this.animations.find(a => a.title === this.functionItem.uiInfo.colorScheme);
      }

      return null;
    },
  },
  watch: {
    functionItem: {
      handler(newVal, oldVal) {
        if (oldVal && newVal.uiInfo.colorScheme === oldVal.uiInfo.colorScheme) return;

        this.anim && this.anim.destroy();
        this.anim = null;

        if (newVal.uiInfo.colorScheme) {
          this.anim = this.$lottie.loadAnimation({
            container: this.$refs.animationContainer,
            animationData: this.animations.find(a => a.title === newVal.uiInfo.colorScheme).data,
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    // this.prepareData();

    // console.log('###animations', this.animations);
  },
  mounted() {
    if (this.functionItem.state.isOn && this.functionItem.uiInfo.colorScheme) {
      this.anim = this.$lottie.loadAnimation({
        container: this.$refs.animationContainer,
        animationData: this.animations.find(a => a.title === this.functionItem.uiInfo.colorScheme).data,
      });
    }

    // setTimeout(() => {
    //   this.currentAnimation = this.animations[1];
    //   // this.anim.setSpeed(5);
    // }, 3000);
  },
  methods: {
    changeTemperature(value) {
      this.temperature = value;
    },

    changeTemperatureApply: debounce(function () {
      this.functionTemperatureState();
    }, 500),

    changeOnOffState(value) {
      this.functionOnOffState(this.functionItem.linkedFunction.metaData.applyable ? true : value);
    },

    // prepareData() {
    //   const { valueRange } = this.functionItem.state;
    //   this.minTemp = valueRange[0];
    //   this.maxTemp = valueRange[valueRange.length - 1];
    //   console.log(this.functionItem.state.value);
    //   this.temperature = this.functionItem.state.value;
    // },

    functionTemperatureState() {
      const requestValue = {
        functionId: this.functionItem.id,
        value: this.temperature,
        parameters: null,
      };

      this.$emit('controlStateChanged', requestValue);
    },

    functionOnOffState(value) {
      const requestValue = {
        functionId: this.functionItem.linkedFunction.id,
        isOn: value,
        parameters: null,
      };
      this.$emit('controlStateChanged', requestValue);
    },

    onIndicatorClick() {
      if (!this.controllable) return;

      const val = this.functionItem.metaData.applyable ? true : !this.functionItem.state.isOn;

      const requestValue = {
        functionId: this.functionItem.id,
        isOn: val,
        parameters: null,
      };
      this.$emit('controlStateChanged', requestValue);

      // if (!this.active && this.controllable && this.deviceConnected) {
      //   console.log('switch on');
      //   const requestValue = {
      //     functionId: this.functionItem.id,
      //     isOn: true,
      //     parameters: null,
      //   };
      //   this.$emit('controlStateChanged', requestValue);
      // }

      // if (this.functionItem.uiInfo.additionalText === 'PM 2.5') {
      //   this.$modal.show('cleanerLegendModal');
      // }
    },
    onLottieInfoClick() {
      this.$modal.show('cleanerLegendModal');
    },
  },
};

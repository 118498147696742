
import { mapActions, mapGetters } from 'vuex';
import { errorServiceHandler } from '@/utils/api';
import QuestionIcon from '@/assets/icons/24x24/additional-modes/question.svg';
import LoadingSpinnerFunction from '@/components/loaders/LoadingSpinnerFunction.vue';
import controlPanelToggle from '@/mixins/controlPanelToggle';

export default {
  name: 'ControlPanelCard',
  components: { QuestionIcon, LoadingSpinnerFunction },
  mixins: [controlPanelToggle],
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    deviceId: {
      type: Number,
      required: true,
    },
    functionBlockId: {
      type: Number,
      required: true,
    },
    functionItem: {
      type: Object,
      required: true,
    },
    globalLoading: {
      type: Boolean,
      default: false,
      required: true,
    },
    isHeater: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      getForceLocalLoadingFunctionId: 'deviceFunctions/getForceLocalLoadingFunctionId',
      getTranslations: 'localization/getTranslations',
    }),
    progressStrokeDashoffset() {
      if (!this.functionItem.progress) return null;

      const circleRadius = 15;
      const progress = Math.round((this.functionItem.progress.progress * 100)
        / this.functionItem.progress.duration);

      const circleLength = Math.PI * (circleRadius * 2); // 94.25
      return ((100 - progress) / 100) * circleLength;
    },
    isHeaterAdditionalSettings() {
      return this.functionItem.metaData.tag === 'HEATER_ADDITIONAL_SETTINGS';
    },
    circleColor() {
      return this.isHeater ? '#F37335' : '#5EA9FF';
    }
  },
  watch: {
    getForceLocalLoadingFunctionId: {
      handler(newValue) {
        if (newValue && newValue === this.functionItem.id) {
          this.localLoading = true;
          this.localLoadingTimeout = setTimeout(() => {
            // Искусственная минимальная задержка для анимации
            this.localLoadingTimeout = null;
            if (!this.globalLoading && this.localLoading) {
              this.localLoading = false;
              this.$store.commit('deviceFunctions/setForceLocalLoadingFunctionId', null);
            }
          }, 1000);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setModalData: 'modalAdditionalMode/setModalData',
    }),
    getFunctionDescription() {
      return this.$axios.get(
        `functions/${this.functionItem.id}/description`,
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        },
      )
        .then((response) => response.data.data)
        .catch((error) => {
          errorServiceHandler.call(this.$store, error);
        });
    },
    async openClueModal(e) {
      e.preventDefault();
      e.stopPropagation();

      const functionDescription = await this.getFunctionDescription();
      if (!functionDescription) return;

      this.setModalData({
        iconSvg: functionDescription.iconSvg || functionDescription.icon,
        title: functionDescription.fullTitle,
        text: functionDescription.description,
      });

      this.$modal.show('additionalModelTip');
    },
    clickContextMenu(event) {
      if (!this.controllable || !(this.functionItem.metaData.hasDescription || this.functionItem.metaData.tag === 'VERTICAL_FLOW_DETAILED')) {
        return;
      }
      event.preventDefault();

      const prepareFunctionData = {
        deviceId: this.deviceId,
        functionBlockId: this.functionBlockId,
      };
      Object.assign(prepareFunctionData, this.functionItem);

      this.$emit('contextMenuShow', [event, prepareFunctionData]);
    },
  },
};

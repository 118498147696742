
export default {
  name: 'ShowCurrentState',
  props: {
    currentState: {
      type: Object,
      required: true
    }
  }
}

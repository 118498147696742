
import { mapActions, mapGetters } from 'vuex';
import modalEsc from '@/mixins/modalEsc';
import AppButton from '../Buttons/AppButton.vue';
import CloseIcon from '../../assets/icons/x.svg';
import SearchInput from '../Inputs/SearchInput.vue';
import CheckboxObject from '../checks/checkbox-object.vue';
import ScheduleEditor from '../schedule/ScheduleEditor.vue';

export default {
  name: 'CreateSchedule',
  components: {
    ScheduleEditor,
    CheckboxObject,
    SearchInput,
    AppButton,
    CloseIcon,
  },
  mixins: [modalEsc],
  data: () => ({
    modalName: 'createEditSchedule',
    preloading: false,
    listOfPlaces: [],
    countChecked: 0,
    SHOW_BY: 21,
    countShowPlaces: 21,
    searchValue: '',
    loadingSave: false,
  }),
  computed: {
    ...mapGetters({
      dashboardData: 'buildingDashboard/getBuildingSelectDashboard',
      buildingId: 'buildings/getBuildingSelectId',
      placesInSchedule: 'schedules/getPlacesInSchedule',
      infoForEditSchedule: 'schedules/getInfoForEditedSchedule',
      typeOfModal: 'schedules/getTypeOfModal',
      step: 'schedules/getModalStep',
      modalShowCount: 'system/getModalShowCount',
      getTranslations: 'localization/getTranslations',
    }),
    isChoicePlace() {
      return this.placesInSchedule.length;
    },
    isAllConfigured() {
      let isAllConfigured = true;
      this.placesInSchedule.forEach((place) => {
        place.periods.forEach((period) => {
          period.times.forEach((time) => {
            if ((!time.action.deviceState.isOn && !time.action.deviceState.functions && !time.action.presetId)
              || (!time.action.presetId && !time.action.deviceState)
            ) {
              isAllConfigured = false;
            }
          });
        });
      });
      return isAllConfigured;
    },
    disableGoNext() {
      if (this.step === 1) {
        return !this.isChoicePlace;
      }
      if (this.step === 2) {
        return !this.isAllConfigured;
      }
      return false;
    },
    mainTitle() {
      if (this.step === 1) {
        return this.getTranslations['Выберите устройства'] || 'Выберите устройства';
      }
      if (this.step === 2) {
        return this.typeOfModal === 'create' ? this.getTranslations['Добавить расписание'] || 'Добавить расписание' : this.getTranslations['Редактировать расписание'] || 'Редактировать расписание';
      }
      return '';
    },
    firstDescription() {
      return this.typeOfModal === 'create'
        ? this.getTranslations['Выберите устройство или устройства, для которых вы хотите создать расписание'] || 'Выберите устройство или устройства, для которых вы хотите создать расписание'
        : this.getTranslations['Выберите устройство или устройства, которые хотите добавить в расписание'] || 'Выберите устройство или устройства, которые хотите добавить в расписание';
    },
    allPlaces() {
      return this.dashboardData ? this.dashboardData.places : [];
    },
    allGroups() {
      return this.dashboardData ? this.dashboardData.groups : [];
    },
    searchPlacesResult() {
      return this.listOfPlaces.filter((place) => place.title.toLowerCase().includes(this.searchValue.toLowerCase()));
    },
    searchPlacesToShow() {
      return this.searchPlacesResult.slice(0, this.countShowPlaces);
    },
    showMorePlacesAmount() {
      let amount = 0;
      const leftToShow = this.searchPlacesResult.length - this.countShowPlaces;
      if (leftToShow >= this.SHOW_BY) {
        amount = this.SHOW_BY;
      } else if (leftToShow > 0) {
        amount = leftToShow;
      }
      return amount;
    },
  },
  methods: {
    ...mapActions({
      toggleModal: 'system/setShowModal',
      fetchBuildingSelectPlaces: 'buildingDashboard/fetchBuildingSelectPlaces',
      editSchedule: 'schedules/editSchedule',
    }),
    checkedPlace(place) {
      return this.placesInSchedule.findIndex((item) => item.placeId === place.id) !== -1;
    },
    checkItem(place) {
      // console.log(place);
      this.$store.commit('schedules/toggleChoicePlace', place);
    },
    searchHandler() {
      this.countShowPlaces = this.SHOW_BY;
    },
    async buttonHandler() {
      if (this.disableGoNext) {
        return;
      }
      if (this.step === 1 && this.placesInSchedule.length) {
        this.$store.commit('schedules/setModalStep', 2);
      } else if (this.step === 2) {
        if (this.typeOfModal === 'create') {
          this.$modal.show('nameSchedule');
        } else {
          this.loadingSave = true;
          const isResponseSuccess = await this.editSchedule();
          this.loadingSave = false;

          if (isResponseSuccess) {
            this.$modal.hide(this.modalName);
          }
        }
      }
    },
    closeHandler() {
      this.$modal.hide(this.modalName);
    },
    async modalOpen() {
      this.toggleModal(true);
      document.addEventListener('keydown', this.clickHandler);
      this.preloading = true;
      await this.fetchBuildingSelectPlaces(this.buildingId);
      this.listOfPlaces.push(...this.allPlaces);
      this.allGroups.forEach((item) => {
        item && this.listOfPlaces.push(...item.places);
      });
      this.preloading = false;
    },
    beforeClosed(e) {
      if (this.modalShowCount > 1) {
        e.cancel();
      }
    },
    modalClosed() {
      this.toggleModal(false);
      document.removeEventListener('keydown', this.clickHandler);
      this.$store.commit('schedules/resetAll');
      this.listOfPlaces = [];
      this.countChecked = 0;
      this.countShowPlaces = this.SHOW_BY;
      this.$store.commit('schedules/setModalStep', 1);
    },
  },
};


import { mapGetters } from "vuex";
import CloseIcon from '../../assets/icons/24x24/close_new.svg';

export default {
  name: "IndicatorModal",
  components: { CloseIcon },
  data: () => ({
    modalName: "indicatorModal"
  }),
  computed: {
    ...mapGetters({
      indicator: "deviceFunctions/getCurrentIndicator",
    }),
  }
};


import QuestionIcon from '@/assets/icons/24x24/additional-modes/question.svg';
import controlPanelToggle from '@/mixins/controlPanelToggle';

export default {
  name: "ControlPanelRadio",
  components: { QuestionIcon },
  mixins: [controlPanelToggle],
  props: {
    functionItem: {
      type: Object,
      required: true
    },
    deviceConnected: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    values: null,
    currentValue: null,
  }),
  computed: {
    blocked() {
      return this.functionItem.state.blocked || !this.deviceConnected;
    },
    controllable() {
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    active() {
      return this.functionItem.state.isOn;
    }
  },
  beforeMount() {
    this.values = this.functionItem.state.stringValueRange;
    this.currentValue = this.functionItem.state.stringValue;
  },
  methods: {
    setValue(newValue) {
      this.currentValue = newValue;
      const requestValue = {
        functionId: this.functionItem.id,
        value: this.currentValue,
        parameters: null,
      };

      this.$emit('controlStateChanged', requestValue);
      this.currentValue = this.functionItem.state.stringValue;
    },

  }
}

import { getRandomIntInclusive } from "@/utils/helpers";
import { errorConflictHandler, errorHandlerWithAction } from "@/utils/api";
import Vue from "vue";

export const state = () => ({
  deviceInfo: {
    state: {
      info: {},
    },
  },
  climateOnlineInfo: null,
  deviceInfoSaveState: null, // откат к последнему состоянию при ошибке запроса
  globalFunctionsLoading: false,
  globalNeedForUpdate: false,
  forceLocalLoadingFunctionId: null,
  currentRecommendation: null,
  currentError: null,
  isClimateOnlineRequest: false,
  comfortSleepData: null,
  comfortSleepDataLoading: false,
  currentIndicator: null,
  currentFunction: null,
  currentGradient: null,
});

export const getters = {
  getDeviceInfo: ({ deviceInfo }) =>
    Object.keys(deviceInfo).length > 1 ? deviceInfo : null,
  getDeviceInfoSaveState: ({ deviceInfoSaveState }) => deviceInfoSaveState,
  getGlobalFunctionsLoading: ({ globalFunctionsLoading }) =>
    globalFunctionsLoading,
  getGlobalNeedForUpdate: ({ globalNeedForUpdate }) => globalNeedForUpdate,
  getForceLocalLoadingFunctionId: ({ forceLocalLoadingFunctionId }) =>
    forceLocalLoadingFunctionId,
  getClimateOnlineInfo: ({ climateOnlineInfo }) => climateOnlineInfo,
  getCurrentRecommendation: ({ currentRecommendation }) => currentRecommendation,
  getCurrentError: ({ currentError }) => currentError,
  getClimateOnlineRequestStatus: ({ isClimateOnlineRequest }) => isClimateOnlineRequest,
  getComfortSleepData: ({ comfortSleepData }) => comfortSleepData,
  getComfortSleepDataLoading: ({ comfortSleepDataLoading }) => comfortSleepDataLoading,
  getCurrentIndicator: ({ currentIndicator }) => currentIndicator,
  getCurrentFunction: ({ currentFunction }) => currentFunction,
  getCurrentGradient: ({ currentGradient }) => currentGradient,
};

export const mutations = {
  pinCurrentPreset(state, pinned) {
    if (state.deviceInfo) {
      state.deviceInfo.pinned = pinned;
    }
  },
  removePreset(state, presetId) {
    if (state.deviceInfo && state.deviceInfo.presets) {
      state.deviceInfo.presets = state.deviceInfo.presets.filter(
        (item) => item.id != presetId
      );
    }
  },
  removePresetGroup(state, presetId) {
    if (state.deviceInfo && state.deviceInfo.groupPresets) {
      state.deviceInfo.groupPresets = state.deviceInfo.groupPresets.filter(
        (item) => item.id != presetId
      );
    }
  },
  pinPreset(state, preset) {
    if (state.deviceInfo && state.deviceInfo.presets) {
      state.deviceInfo.presets = state.deviceInfo.presets.map((item) => {
        if (item.id === preset.id) {
          item.pinned = !item.pinned;
        }
        return item;
      });
    }
  },
  pinPresetGroup(state, group) {
    if (state.deviceInfo && state.deviceInfo.groupPresets) {
      state.deviceInfo.groupPresets = state.deviceInfo.groupPresets.map(
        (item) => {
          if (item.id === group.id) {
            item.pinned = !item.pinned;
          }
          return item;
        }
      );
    }
  },
  setDeviceInfo(state, deviceInfo) {
    state.deviceInfo = deviceInfo;
  },
  setClimateOnlineInfo(state, climateOnlineInfo) {
    state.climateOnlineInfo = climateOnlineInfo;
  },
  setCurrentRecommendation(state, currentRecommendation) {
    state.currentRecommendation = currentRecommendation;
  },
  setCurrentError(state, currentError) {
    state.currentError = currentError;
  },
  setClimateOnlineRequestStatus(state, status) {
    state.isClimateOnlineRequest = status;
  },
  setDeviceInfoCurrentSchedule(state) {
    if (!Object.prototype.hasOwnProperty.call(state.deviceInfo, "timer")) {
      Vue.set(state.deviceInfo, "currentSchedule", null);
    }
  },
  setDeviceInfoSubscriptionProp(state) {
    // фикс реактивности свойства subscription
    if (
      !Object.prototype.hasOwnProperty.call(state.deviceInfo, "subscription")
    ) {
      Vue.set(state.deviceInfo, "subscription", null);
    }
  },
  setDeviceInfoSaveState(state, deviceInfo) {
    state.deviceInfoSaveState = deviceInfo;
  },
  setGlobalFunctionsLoading(state, value) {
    state.globalFunctionsLoading = value;
  },
  setGlobalNeedForUpdate(state, value) {
    state.globalNeedForUpdate = value;
  },
  setPresetData(state, data) {
    if (state.deviceInfo && state.deviceInfo.presets) {
      state.deviceInfo.presets = state.deviceInfo.presets.map((item) => {
        if (item.id === data.id) {
          return Object.assign(item, data);
        }
        return item;
      });
    }
  },
  addPreset(state, data) {
    state.deviceInfo.presets.push(data);
  },
  setGroupPresetData(state, data) {
    if (state.deviceInfo && state.deviceInfo.groupPresets) {
      state.deviceInfo.groupPresets = state.deviceInfo.groupPresets.map(
        (item) => {
          if (item.id === data.id) {
            return Object.assign(item, data);
          }
          return item;
        }
      );
    }
  },
  setForceLocalLoadingFunctionId(state, value) {
    state.forceLocalLoadingFunctionId = value;
  },
  setComfortSleepData(state, value) {
    state.comfortSleepData = value;
  },
  setComfortSleepDataLoading(state, value) {
    state.comfortSleepDataLoading = value;
  },
  setCurrentIndicator(state, value) {
    state.currentIndicator = value;
  },
  setCurrentFunction(state, value) {
    state.currentFunction = value;
  },
  setCurrentGradient(state, value) {
    state.currentGradient = value;
  },
};

export const actions = {
  fetchDeviceInfo({ commit, dispatch }, deviceId) {
    return this.$axios
      .get(`devices/${deviceId}`, {
        headers: {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      })
      .then((response) => {
        const responseData = response.data.data;

        // Проверка currentSchedule на наличие ошибок
        if (responseData.currentSchedule) {
          let scheduleHasError = false;
          let checkDays = [];

          responseData.currentSchedule.periods.map(period => {
            // Проверяем на наличие одинаковых дней
            if (period.weekDays.filter(value => checkDays.includes(value)).length) {
              scheduleHasError = true;
            }
            checkDays = [...checkDays, ...period.weekDays];

            // Проверяем на отсутствие периода со значением 0 (стартового),
            // также проверяем на наличие одинаковых точек старта
            if (
              !period.times.filter(value => value.start === 0).length
              ||
              period.times.reduce((acc, value) => {
                if (!acc.includes(value)) {
                  acc.push(value.start);
                }
                return acc;
              }, []).length != period.times.length) {
              scheduleHasError = true;
            }
          })

          if (scheduleHasError) {
            responseData.currentSchedule === null;
          }
        }

        // console.log('CLEANER###', responseData.pult);
        // responseData.pult = [
        //   {
        //       "id": 2099999,
        //       "title": null,
        //       "functions": [
        //           {
        //               "id": 649,
        //               "title": null,
        //               "uiInfo": {
        //                   "controlType": "LOTTIE_CIRCLE",
        //                   "units": "°C",
        //                   "displayInStateAsText": true,
        //                   "colorScheme": "SCHEME_2",
        //                   "additionalText": "Нажмите для включения"
        //               },
        //               "state": {
        //                   "value": 23,
        //                   "isOn": false,
        //                   "blocked": true,
        //                   "controllable": true,
        //                   "valueRange": [
        //                       16,
        //                       30
        //                   ]
        //               },
        //               "metaData": {
        //                   "applyable": false,
        //                   "hasDescription": true,
        //                   "tag": null,
        //                   "isPowerOnFunction": false,
        //                   "ignorePowerOff": false,
        //                   "bleTagInfo": {
        //                       "bleTag": "setTemp",
        //                       "bleOnCommand": null,
        //                       "bleOffCommand": null
        //                   }
        //               },
        //               "progress": null,
        //               "linkedFunction": null
        //           }
        //       ]
        //   },
          // {
          //     "id": 999,
          //     "title": null,
          //     "functions": [
          //         {
          //             "id": 21,
          //             "title": null,
          //             "uiInfo": {
          //                 "controlType": "LOTTIE_CIRCLE",
          //                 "units": "ug/m3",
          //                 "displayInStateAsText": true,
          //                 "colorScheme": "SCHEME_2",
          //                 "additionalText": "PM 2.5"
          //             },
          //             "state": {
          //                 "value": 25,
          //                 "isOn": true,
          //                 "blocked": true,
          //                 "controllable": false,
          //                 "valueRange": [
          //                     16,
          //                     30
          //                 ]
          //             },
          //             "metaData": {
          //                 "applyable": false,
          //                 "hasDescription": true,
          //                 "tag": null,
          //                 "isPowerOnFunction": false,
          //                 "ignorePowerOff": false,
          //                 "bleTagInfo": {
          //                     "bleTag": "setTemp",
          //                     "bleOnCommand": null,
          //                     "bleOffCommand": null
          //                 }
          //             },
          //             "progress": null,
          //             "linkedFunction": null
          //         }
          //     ]
          // },
        //   ...responseData.pult,
        // ]

        // const modes = responseData.pult.find(i => i.title === 'Режим работы');
        // modes && (modes.functions = [
        //   {
        //       "id": 227,
        //       "title": "Охлаждение",
        //       "uiInfo": {
        //           "controlType": "ICON",
        //           "units": null,
        //           "icon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/24\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "iconSvg": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_svg\/original\/18.svg?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "onIcon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/24\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "offIcon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_inactive\/x4\/24\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "stateIcon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/14\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "rangeIconsInfo": [],
        //           "displayInStateAsText": false,
        //           "displaySpecialBackground": true
        //       },
        //       "state": {
        //           "value": null,
        //           "isOn": false,
        //           "blocked": false,
        //           "controllable": true
        //       },
        //       "metaData": {
        //           "applyable": true,
        //           "hasDescription": true,
        //           "tag": null,
        //           "isPowerOnFunction": false,
        //           "ignorePowerOff": false,
        //           "bleTagInfo": {
        //               "bleTag": "mode",
        //               "bleOnCommand": "cool",
        //               "bleOffCommand": null
        //           }
        //       },
        //       "progress": null,
        //       "linkedFunction": null
        //   },
        //   {
        //       "id": 229,
        //       "title": "Охлаждение",
        //       "uiInfo": {
        //           "controlType": "ICON",
        //           "units": null,
        //           "icon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/24\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "iconSvg": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_svg\/original\/18.svg?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "onIcon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/24\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "offIcon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_inactive\/x4\/24\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "stateIcon": "https:\/\/dev-split.daichicloud.ru\/storage\/fu-icons\/url_active\/x4\/14\/18.png?h=f2a07e204bba5fcce0d9d3415777ecae",
        //           "rangeIconsInfo": [],
        //           "displayInStateAsText": false,
        //           "displaySpecialBackground": false,
        //           "additionalText": "02:00"
        //       },
        //       "state": {
        //           "value": null,
        //           "isOn": true,
        //           "blocked": false,
        //           "controllable": false
        //       },
        //       "metaData": {
        //           "applyable": true,
        //           "hasDescription": true,
        //           "tag": null,
        //           "isPowerOnFunction": false,
        //           "ignorePowerOff": false,
        //           "bleTagInfo": {
        //               "bleTag": "mode",
        //               "bleOnCommand": "cool",
        //               "bleOffCommand": null
        //           }
        //       },
        //       "progress": null,
        //       "linkedFunction": null
        //   },
        //   ...modes.functions,
        // ]);

        commit("setDeviceInfo", responseData);
        commit("setDeviceInfoSubscriptionProp");
        return responseData;
      })
      .catch((error) => {
        errorConflictHandler.call(this, error);
        dispatch("system/togglePanelActive", false, { root: true });
      });
  },

  fetchClimateOnline({ commit, dispatch }, { deviceId, onClose }) {
    return this.$axios
      .get(`devices/${deviceId}/climate-online/info`, {
        headers: {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      })
      .then((response) => {
        const responseData = response.data.data;
        commit("setClimateOnlineInfo", responseData);
        return responseData;
      })
      .catch((err) => {
        commit("setClimateOnlineInfo", null);
        errorHandlerWithAction.call(this, err, {
          onRepeat: () => dispatch("fetchClimateOnline", { deviceId, onClose }),
          onClose,
        });
      });
  },

  fetchRecommendation({ commit, dispatch }, { deviceId, recommendationId, onClose }) {
    return this.$axios
      .get(`/devices/${deviceId}/climate-online/recommendations/${recommendationId}`, {
        headers: {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      })
      .then((response) => {
        const responseData = response.data.data;
        commit("setCurrentRecommendation", responseData);
        return responseData;
      })
      .catch((err) => {
        commit("setCurrentRecommendation", null);
        errorHandlerWithAction.call(this, err, {
          onRepeat: () => dispatch("fetchRecommendation", { deviceId, recommendationId, onClose }),
          onClose,
        });
      });
  },

  fetchError({ commit, dispatch }, { deviceId, errorId, onClose }) {
    return this.$axios
      .get(`/devices/${deviceId}/climate-online/errors/${errorId}`, {
        headers: {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      })
      .then((response) => {
        const responseData = response.data.data;
        commit("setCurrentError", responseData);
        return responseData;
      })
      .catch((err) => {
        commit("setCurrentError", null);
        errorHandlerWithAction.call(this, err, {
          onRepeat: () => dispatch("fetchError", { deviceId, errorId, onClose }),
          onClose,
        });
      });
  },

  getClimateOnlinePrice({ commit, dispatch }, { deviceId, onClose }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/payments/price?type=CLIMATE_ONLINE_SUBSCRIPTION&deviceId=${deviceId}`, {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("getClimateOnlinePrice", { deviceId, onClose }),
            onClose,
          });
        });
    });
  },

  getPurchasedClimateOnlineOrders({ commit, dispatch }, { deviceId, onClose }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`/payments/purchased-orders?type=CLIMATE_ONLINE_SUBSCRIPTION&deviceId=${deviceId}`, {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("getPurchasedClimateOnlineOrders", { deviceId, onClose }),
            onClose,
          });
        });
    });
  },

  activateClimateOnlineOrder({ commit, dispatch }, orderId) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`/payments/orders/${orderId}/activate`, {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("activateClimateOnlineOrder", orderId),
            onClose: null,
          });
        });
    });
  },

  sendSupportRequest({ commit, dispatch }, { deviceId, onClose }) {
    return this.$axios
      .put(`/devices/${deviceId}/climate-online/support-request`, {
        headers: {
          Authorization: `Bearer ${this.$auth.user.token}`,
        },
      })
      .then((response) => {
        const responseData = response.data.data;
        return responseData;
      })
      .catch((err) => {
        errorHandlerWithAction.call(this, err, {
          onRepeat: () => dispatch("sendSupportRequest", { deviceId, onClose }),
          onClose,
        });
      });
  },

  setClimateOnlineCity({ commit, dispatch }, { deviceId, city, onClose }) {
    return this.$axios
      .put(
        `/devices/${deviceId}/climate-online/city`,
        {
          city,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        },
      )
      .then((response) => {
        const responseData = response.data.data;
        return responseData;
      })
      .catch((err) => {
        errorHandlerWithAction.call(this, err, {
          onRepeat: () => dispatch("setClimateOnlineCity", { deviceId, city, onClose }),
          onClose,
        });
      });
  },

  createClimateOnlineOrder({ commit, dispatch }, { requestBody, onClose }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('/payments/orders',
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("createClimateOnlineOrder", { requestBody, onClose }),
            onClose,
          });
        });
    });
  },

  sendClimateOnlinePromocode({ commit, dispatch }, { requestBody, deviceId, onClose }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(
          `/devices/${deviceId}/climate-online/activate`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("sendClimateOnlinePromocode", { requestBody, deviceId, onClose }),
            onClose,
          });
        });
    });
  },

  closeClimateOnlineRecommendation({ commit, dispatch }, { recommendationId, deviceId, onClose }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .put(
          `/devices/${deviceId}/climate-online/recommendations/${recommendationId}/close`,
          {
            headers: {
              Authorization: `Bearer ${this.$auth.user.token}`,
            },
          },
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("closeClimateOnlineRecommendation", { recommendationId, deviceId, onClose }),
            onClose,
          });
        });
    });
  },

  applyDeviceFunction(
    { commit, getters, dispatch, rootState },
    deviceFunctionControl
  ) {
    if (getters.getGlobalFunctionsLoading) return;
    commit("setGlobalFunctionsLoading", true);
    commit("setDeviceInfoSaveState", getters.getDeviceInfo);

    const requestBody = {
      cmdId: getRandomIntInclusive(0, 99999999),
      value: deviceFunctionControl,
      conflictResolveData: null,
    };

    commit('setComfortSleepDataLoading', true);

    this.$axios
      .post(
        `/devices/${getters.getDeviceInfo.id}/ctrl?ignoreConflicts=false`,
        requestBody,
        {
          Authorization: `Bearer ${this.$auth.user.token}`,
        }
      )
      .then((response) => {
        const systemStateUpdate = response.data.data;
        dispatch("findCurrentDevice", systemStateUpdate);
        const updatedDevices = systemStateUpdate.devices;
        for (const updated in updatedDevices) {
          dispatch("buildingDashboard/setPlaceData", updatedDevices[updated], {
            root: true,
          });
        }

        this.$modal.hide('comfortSleepModal');
      })
      .catch((error) => {
        errorConflictHandler.call(this, error);
        dispatch("updateDeviceInfo", getters.getDeviceInfoSaveState);
      })
      .finally(() => {
        commit("setDeviceInfoSaveState", null);
        commit("setGlobalFunctionsLoading", false);
        commit("setGlobalNeedForUpdate", true);
        setTimeout(() => {
          commit("setGlobalNeedForUpdate", false);
        }, 0);

        commit('setComfortSleepDataLoading', false);
      });
  },

  findCurrentDevice({ dispatch, getters }, systemStateUpdate) {
    if (!getters.getDeviceInfo) return;

    let devices = [];
    if (systemStateUpdate.devices) {
      devices = systemStateUpdate.devices;
    } else if (systemStateUpdate.places) {
      // В запросе на отмену таймера приходит places вместо devices
      devices = systemStateUpdate.places;
    }
    const currentDevice = devices.find(
      (device) => device.id === getters.getDeviceInfo.id
    );

    if (currentDevice) {
      dispatch("updateDeviceInfo", currentDevice);
    }
  },
  updateDeviceInfo({ commit, getters }, currentDevice) {
    if (!getters.getDeviceInfo) return;
    const newDeviceInfo = {};
    Object.assign(newDeviceInfo, getters.getDeviceInfo, currentDevice);
    commit("setDeviceInfo", newDeviceInfo);
  },

  cancelTimer(store, timerId) {
    this.$axios
      .delete(`/timers/${timerId}?ignoreConflicts=false`, {
        Authorization: `Bearer ${this.$auth.user.token}`,
      })
      .catch((error) => {
        errorConflictHandler.call(this, error);
      });
  },

  getDeviceFunctionDetails({ commit, dispatch }, { functionId, deviceId, onClose }) {
    return new Promise((resolve, reject) => {
      const url = deviceId
        ? `/functions/${functionId}/details?deviceId=${deviceId}`
        : `/functions/${functionId}/details`;

      this.$axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        })
        .then((res) => {
          const newData = {
            ...res.data.data,
            id: functionId,
          };

          // newData.targetTempRange = [18, 26];
          // newData.tempCount = 7;
          // newData.targetTempIndex = 3;

          // newData.middlePoints = [
          //   ...newData.middlePoints,
          //   {
          //     tempIndex: 5,
          //     time: 120,
          //   },
          //   {
          //     tempIndex: 2,
          //     time: 250,
          //   },
          //   {
          //     tempIndex: 2,
          //     time: 300,
          //   },
          // ];

          commit('setComfortSleepData', newData);
          resolve(res.data.data);
        })
        .catch((err) => {
          errorHandlerWithAction.call(this, err, {
            onRepeat: () => dispatch("getDeviceFunctionDetails", { functionId, deviceId, onClose }),
            onClose,
          });
        });
    });
  },

};

import {
  errorConflictHandler,
  errorHandler,
  errorServiceHandler,
} from "../utils/api";

export const state = () => ({
  presetTitle: "",
  pinned: true,
  dataToSend: null,
  loading: false,
  typeOfModal: null,
  typeOfCommand: null,
  currentPresetToChange: null,
  dataEditGroupPreset: null,
});

export const getters = {
  getLoading: ({ loading }) => loading,
  getPinned: ({ pinned }) => pinned,
  getPresetTitle: ({ presetTitle }) => presetTitle,
  getTypeOfModal: ({ typeOfModal }) => typeOfModal,
  getTypeOfCommand: ({ typeOfCommand }) => typeOfCommand,
  getCurrentPresetToChange: ({ currentPresetToChange }) =>
    currentPresetToChange,
  getDataEditGroupPreset: ({ dataEditGroupPreset }) => dataEditGroupPreset,
};

export const mutations = {
  setDataToSend(state, value) {
    //console.log(value);
    state.dataToSend = value;
  },
  setTitle(state, value) {
    state.presetTitle = value;
  },
  setPinned(state, value) {
    state.pinned = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setTypeOfModal(state, value) {
    state.typeOfModal = value;
  },
  setTypeOfCommand(state, value) {
    state.typeOfCommand = value;
  },
  setCurrentPresetToChange(state, value) {
    state.currentPresetToChange = value;
  },
  setEditGroupData(state, value) {
    state.dataEditGroupPreset = value;
  },
};

export const actions = {
  async createCommand({ state, rootState, commit }) {
    commit("setLoading", true);
    const request = {
      title: state.presetTitle,
      pinned: state.pinned,
      buildingId: rootState.buildings.buildingSelectId,
    };
    if (state.dataToSend.length > 1) {
      request.content = [];
      state.dataToSend.forEach((device) => {
        const { type } = device.presetData;
        const deviceInfo = {
          placeId: device.presetData.placeId || device.id,
        };
        if (type === "current") {
          deviceInfo.presetId = null;
          deviceInfo.deviceState = {
            isOn: device.presetData.state.isOn,
            functions: device.presetData.functions,
          };
        } else if (type === "off") {
          deviceInfo.presetId = null;
          deviceInfo.deviceState = { isOn: false, functions: [] };
        } else if (type === "preset") {
          deviceInfo.presetId = device.presetData.presetId;
          deviceInfo.deviceState = null;
        } else if (type === "custom") {
          deviceInfo.presetId = null;
          deviceInfo.deviceState = {
            isOn: true,
          };
          const functions = [];
          device.pult.forEach((item) => {
            item.functions.forEach((func) => {
              const funcToReturn = {};
              funcToReturn.isOn = func.state.isOn;
              funcToReturn.functionId = func.id;
              funcToReturn.value = func.state.value;
              funcToReturn.parameters = func.metaData.parameters;
              functions.push(funcToReturn);

              if (func.linkedFunction) {
                const funcToReturnLinked = {};
                funcToReturnLinked.isOn = func.linkedFunction.state.isOn;
                funcToReturnLinked.functionId = func.linkedFunction.id;
                funcToReturnLinked.value = func.linkedFunction.state.value;
                funcToReturnLinked.parameters =
                  func.linkedFunction.metaData.parameters;
                functions.push(funcToReturnLinked);
              }
            });
          });
          deviceInfo.deviceState.functions = functions;
        }
        request.content.push(deviceInfo);
      });
      try {
        const response = await this.$axios.post("groupPresets", request);
        commit("fastCommandsPage/ADD_GROUP_PRESET", response.data.data, {
          root: true,
        });
        commit("setLoading", false);
      } catch (error) {
        errorConflictHandler.call(this, error);
        console.log(error);
        commit("setLoading", false);
      }
    } else {
      const deviceInfo = state.dataToSend[0].presetData;
      request.placeId = deviceInfo.placeId || state.dataToSend[0].id;
      if (deviceInfo.type === "current") {
        request.deviceState = null;
        request.presetId = null;
      } else if (deviceInfo.type === "off") {
        request.presetId = null;
        request.deviceState = { isOn: false, functions: [] };
      } else if (deviceInfo.type === "preset") {
        request.presetId = deviceInfo.id;
        request.deviceState = null;
      } else if (deviceInfo.type === "custom") {
        request.presetId = null;
        request.deviceState = {
          isOn: true,
        };
        const functions = [];
        state.dataToSend[0].pult.forEach((item) => {
          item.functions.forEach((func) => {
            const funcToReturn = {};
            funcToReturn.isOn = func.state.isOn;
            funcToReturn.functionId = func.id;
            funcToReturn.value = func.state.value;
            funcToReturn.parameters = func.metaData.parameters;

            functions.push(funcToReturn);

            if (func.linkedFunction) {
              const funcToReturnLinked = {};
              funcToReturnLinked.isOn = func.linkedFunction.state.isOn;
              funcToReturnLinked.functionId = func.linkedFunction.id;
              funcToReturnLinked.value = func.linkedFunction.state.value;
              funcToReturnLinked.parameters =
                func.linkedFunction.metaData.parameters;
              functions.push(funcToReturnLinked);
            }
          });
        });
        request.deviceState.functions = functions;
      }
      try {
        // console.log(request);
        const response = await this.$axios.post("presets", request);
        commit("fastCommandsPage/ADD_PLACE_PRESET", response.data.data, {
          root: true,
        });
        commit("setLoading", false);
      } catch (error) {
        errorConflictHandler.call(this, error);
        commit("setLoading", false);
        console.log(error);
      }
    }
  },
  async fetchPultForConfiguration({ commit }, id) {
    try {
      const { data } = await this.$axios.get(`devices/${id}/pults?type=preset`);
      return data.data;
    } catch (error) {
      errorServiceHandler.call(this, error);
    }
  },
  async fetchDevicesPresets({ state }, id) {
    try {
      const { data } = await this.$axios.get(`devices/${id}`);
      return data.data.presets;
    } catch (error) {
      errorServiceHandler.call(this, error);
      return [];
    }
  },
  async fetchConfigurationForPreset({ state }, { id, type }) {
    try {
      const { data } = await this.$axios.get(
        `devices/${id}/configuration-options?type=${type}`
      );
      return data.data;
    } catch (error) {
      errorServiceHandler.call(this, error);
      return {};
    }
  },
  async fetchPultConfigurate({ state }, configure) {
    try {
      const { data } = await this.$axios.post(
        `devices/${configure.id}/ctrl/virtual`,
        configure.configuration
      );
      return data.data;
    } catch (error) {
      errorServiceHandler.call(this, error);
    }
  },
  async changeCommand({ state, commit, dispatch }) {
    const request = {
      title: state.currentPresetToChange.title,
    };
    if (state.currentPresetToChange.contents) {
      request.content = [];
      state.dataToSend.forEach((device) => {
        const { type } = device.presetData;
        const deviceInfo = {
          placeId: device.presetData.placeId || device.id,
        };
        if (type === "current") {
          deviceInfo.presetId = null;
          deviceInfo.deviceState = {
            isOn: true,
          };
          if (device.presetData.functions) {
            const functions = [];
            device.presetData.functions.forEach((func) => {
              const funcToReturn = {};
              funcToReturn.isOn = func.isOn;
              funcToReturn.functionId = func.functionId;
              funcToReturn.value = func.value;
              if (func.parameters) {
                funcToReturn.parameters = func.parameters;
              }
              functions.push(funcToReturn);

              if (func.linkedFunction) {
                const funcToReturnLinked = {};
                funcToReturnLinked.isOn = func.linkedFunction.state.isOn;
                funcToReturnLinked.functionId = func.linkedFunction.id;
                funcToReturnLinked.value = func.linkedFunction.state.value;
                funcToReturnLinked.parameters =
                  func.linkedFunction.metaData.parameters;
                functions.push(funcToReturnLinked);
              }
            });
            deviceInfo.deviceState.functions = functions;
          }
          deviceInfo.presetId = null;
        } else if (type === "off") {
          deviceInfo.presetId = null;
          deviceInfo.deviceState = { isOn: false, functions: [] };
        } else if (type === "preset") {
          deviceInfo.presetId = device.presetData.id;
          deviceInfo.deviceState = null;
        } else if (type === "custom") {
          deviceInfo.presetId = null;
          deviceInfo.deviceState = {
            isOn: true,
          };
          const functions = [];
          device.pult.forEach((item) => {
            item.functions.forEach((func) => {
              const funcToReturn = {};
              funcToReturn.isOn = func.state.isOn;
              funcToReturn.functionId = func.id;
              funcToReturn.value = func.state.value;
              funcToReturn.parameters = func.metaData.parameters;
              functions.push(funcToReturn);

              if (func.linkedFunction) {
                const funcToReturnLinked = {};
                funcToReturnLinked.isOn = func.linkedFunction.state.isOn;
                funcToReturnLinked.functionId = func.linkedFunction.id;
                funcToReturnLinked.value = func.linkedFunction.state.value;
                funcToReturnLinked.parameters =
                  func.linkedFunction.metaData.parameters;
                functions.push(funcToReturnLinked);
              }
            });
          });
          deviceInfo.deviceState.functions = functions;
        }
        request.content.push(deviceInfo);
      });
      request.conflictResolveData = null;

      try {
        const response = await this.$axios.put(
          `groupPresets/${state.currentPresetToChange.id}`,
          request
        );

        commit(
          "fastCommandsPage/EDIT_GROUP_PRESET",
          response.data.data.result,
          { root: true }
        );

        const { systemStateUpdate } = response.data.data;
        const updatedDevices = systemStateUpdate.devices;
        for (const updated in updatedDevices) {
          dispatch("buildingDashboard/setPlaceData", updatedDevices[updated], {
            root: true,
          });
        }
        dispatch("buildingDashboard/updatePresets", systemStateUpdate.presets, {
          root: true,
        });
        dispatch(
          "buildingDashboard/updateGroupPresets",
          systemStateUpdate.groupPresets,
          { root: true }
        );
        dispatch(
          "buildingDashboard/updateSchedules",
          systemStateUpdate.schedules,
          { root: true }
        );
      } catch (error) {
        errorHandler.call(this, error);
      }
    } else {
      const deviceInfo = state.dataToSend[0].presetData;
      request.placeId = deviceInfo.placeId || state.dataToSend[0].id;
      if (deviceInfo.type === "current") {
        request.presetId = null;
        request.deviceState = {
          isOn: deviceInfo.deviceState.isOn,
          functions: deviceInfo.deviceState.functions,
        };
      } else if (deviceInfo.type === "off") {
        request.presetId = null;
        request.deviceState = { isOn: false };
      } else if (deviceInfo.type === "preset") {
        request.presetId = deviceInfo.id;
        request.deviceState = null;
      } else if (deviceInfo.type === "custom") {
        request.presetId = null;
        request.deviceState = {
          isOn: true,
        };
        const functions = [];
        state.dataToSend[0].pult.forEach((item) => {
          item.functions.forEach((func) => {
            const funcToReturn = {};
            funcToReturn.isOn = func.state.isOn;
            funcToReturn.functionId = func.id;
            funcToReturn.value = func.state.value;
            funcToReturn.parameters = func.metaData.parameters;
            functions.push(funcToReturn);

            if (func.linkedFunction) {
              const funcToReturnLinked = {};
              funcToReturnLinked.isOn = func.linkedFunction.state.isOn;
              funcToReturnLinked.functionId = func.linkedFunction.id;
              funcToReturnLinked.value = func.linkedFunction.state.value;
              funcToReturnLinked.parameters =
                func.linkedFunction.metaData.parameters;
              functions.push(funcToReturnLinked);
            }
          });
        });
        request.deviceState.functions = functions;
      }

      try {
        const response = await this.$axios.put(
          `presets/${state.currentPresetToChange.id}?ignoreConflicts=false`,
          request
        );
        commit(
          "fastCommandsPage/EDIT_PLACE_PRESET",
          response.data.data.result,
          { root: true }
        );

        const { systemStateUpdate } = response.data.data;
        const updatedDevices = systemStateUpdate.devices;
        for (const updated in updatedDevices) {
          dispatch("buildingDashboard/setPlaceData", updatedDevices[updated], {
            root: true,
          });
        }
        dispatch("buildingDashboard/updatePresets", systemStateUpdate.presets, {
          root: true,
        });
        dispatch(
          "buildingDashboard/updateGroupPresets",
          systemStateUpdate.groupPresets,
          { root: true }
        );
        dispatch(
          "buildingDashboard/updateSchedules",
          systemStateUpdate.schedules,
          { root: true }
        );
      } catch (error) {
        errorHandler.call(this, error);
      }
    }
  },
  async fetchEditGroupPresetData({ state, commit }, groupPresetId) {
    try {
      const { data } = await this.$axios.get(`groupPresets/${groupPresetId}`);
      commit("setEditGroupData", data.data);
    } catch (error) {
      errorServiceHandler.call(this, error);
    }
  },
};


import RoundSlider from 'vue-round-slider';
import RingLine from '@/components/control-panel/RingLine.vue';
import InputRange from '@/components/Inputs/InputRange';
import ButtonCount from '@/components/Buttons/ButtonCount';
import ToggleButton from '@/components/Buttons/ToggleButton';
import { debounce } from '@/utils/helpers';

export default {
  name: 'ControlPanelRound',
  components: { ButtonCount, InputRange, ToggleButton, RoundSlider, RingLine },
  props: {
    deviceConnected: {
      type: Boolean,
      required: true,
    },
    functionBlockId: {
      type: Number,
      required: true,
    },
    functionItem: {
      type: Object,
      required: true,
    },
    needForUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    minTemp: 0,
    maxTemp: 40,
    temperature: 0,
    sendTemperature: 0,
    lastValue: 0,
    timers: {},
    timerCounter: 0,
  }),
  computed: {
    tempUnitSymbol() {
      if (this.functionItem && this.functionItem.uiInfo && this.functionItem.uiInfo.units) {
        return this.functionItem.uiInfo.units.replace('℃', '°С');
      }
      return '';
    },
    blocked() {
      return this.functionItem.state.blocked || !this.deviceConnected;
    },
    controllable() {
      return this.functionItem.state.controllable && this.deviceConnected;
    },
    active() {
      return this.functionItem.state.isOn;
    },
    controllableToggle() {
      return this.functionItem.linkedFunction?.state.controllable && this.deviceConnected;
    },
    onOffState() {
      return this.functionItem.linkedFunction?.state.isOn;
    },
  },
  watch: {
    functionItem: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && !this.timers['valueSend'] && !this.timers['repeatChange']) {
          this.prepareData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeMount() {
    this.prepareData();
  },
  methods: {
    changeTemperature(value) {
      if (!this.timers['valueSend'] && !this.timers['repeatChange']) {
        this.temperature = value;
        this.changeTemperatureTimer();
      }
    },
    changeTemperatureTimer() {
      if (this.timers['valueSend']) return;

      this.timers['valueSend'] = setInterval(() => {
        if (this.temperature === this.lastValue) {
          clearInterval(this.timers['valueSend']);
          this.sendTemperature = this.temperature;
          this.timers['valueSend'] = null;
          this.functionTemperatureState();
          return;
        }
        this.temperature = this.functionItem.state.value;
        this.lastValue = this.temperature;
      }, 500);
    },
    changeTemperatureApply: debounce(function () {
      this.functionTemperatureState();
    }, 500),

    changeOnOffState(value) {
      this.functionOnOffState(this.functionItem.linkedFunction.metaData.applyable ? true : value);
    },

    prepareData() {
      const { valueRange } = this.functionItem.state;
      this.minTemp = valueRange[0];
      this.maxTemp = valueRange[valueRange.length - 1];
      this.temperature = this.functionItem.state.value;
    },

    functionTemperatureState() {
      this.temperature = this.sendTemperature;
      const requestValue = {
        functionId: this.functionItem.id,
        value: this.temperature,
        parameters: null,
      };

      this.$emit('controlStateChanged', requestValue);
    },

    functionOnOffState(value) {
      const requestValue = {
        functionId: this.functionItem.linkedFunction.id,
        isOn: value,
        parameters: null,
      };
      this.$emit('controlStateChanged', requestValue);
    },

    checkMargins(event) {
      if (event.value > this.maxTemp || event.value < this.minTemp)
        return false;
    },

    repeatChangeValue(addend) {
      this.timers['repeatChange'] = this.timers['repeatChange'] ?? null;

      if (this.timers['repeatChange']) clearInterval(this.timers['repeatChange']);
      
      const timerDelay = this.timerCounter < 5 ? 250 : 100;
      this.timers['repeatChange'] = setInterval(() => {
        if ((this.temperature === this.maxTemp && addend === 1) || (this.temperature === this.minTemp && addend === -1)) {
          this.repeatChangeValueStop();
          return;
        }
        this.temperature += addend;
        this.timerCounter++;
        if (this.timerCounter === 5) {
          this.repeatChangeValue(addend);
        }
      }, timerDelay);
    },

    repeatChangeValueStop() {
      if (!this.timers['repeatChange']) return;

      clearInterval(this.timers['repeatChange']);
      this.timers['repeatChange'] = null;
      this.timerCounter = 0;
      this.sendTemperature = this.temperature;
      this.changeTemperatureTimer();
    },
  },
};

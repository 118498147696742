
import { mapGetters } from 'vuex';
import PlaceStatus from '@/components/class/PlaceStatus';
import ShowStateIcons from '@/components/small/ShowStateIcons';
import ShowStateDetails from '@/components/small/ShowStateDetails';
import { secondsToHHMM } from '@/utils/helpers';
import ScheduleIcon from '../assets/icons/14x14/schedule_new_14.svg';
import TimeIcon from '../assets/icons/14x14/timer_new.svg';

export default {
  name: 'StateBadge',
  components: {
    TimeIcon, ScheduleIcon, ShowStateIcons, ShowStateDetails
  },
  props: {
    deviceInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isPassiveSchedule: true,
    stateDetails: {},
  }),
  computed: {
    ...mapGetters({
      panelDeviceId: 'system/getPanelDeviceId',
      getTranslations: 'localization/getTranslations',
    }),
    weekDayName() {
      return ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']]
    },
    placeStatus() {
      return new PlaceStatus(this.deviceInfo);
    },
    progressValue() {
      return this.placeStatus.progressPercentProgress();
    },
    progressTimeLeft() {
      return this.placeStatus.progressLeftInHHMM();
    },
    timerProgressValue() {
      return this.placeStatus.timerPercentProgress();
    },
    timerTimeLeft() {
      return this.placeStatus.timerLeftHHMM();
    },
    currentTimeInterval() {
      const timeInterval = this.placeStatus.scheduleCurrentTimeIntervalActive();
      if (timeInterval) {
        return `${secondsToHHMM(timeInterval.start * 60)} - ${secondsToHHMM(timeInterval.end * 60)}`;
      }
      return '';
    },
    badgeActiveState() {
      if (this.placeStatus.isScheduleShow()) {
        if (this.placeStatus.isScheduleActive()) {
          if (this.placeStatus.isCurrentPeriodActive()) {
            this.isPassiveSchedule = false;
          }
          return 'schedule'; // Активное расписание
        }
        return 'scheduleInFuture'; // Расписание установлено, но заработает в будущем
      }

      if (this.deviceInfo.timer) {
        if (this.deviceInfo.timer.progress.progress !== null) {
          return 'timerProgress'; // Таймер запущен
        }
        return 'timer'; // Таймер отложен
      }
      /* if (this.placeStatus.isTimerShow()) {
        if (this.placeStatus.isTimerPause()) {
          return 'timer'; // {{ getTranslations['Таймер'] }} отложен
        }
        return 'timerProgress'; // Таймер запущен
      } */

      if (this.deviceInfo.progress) {
        return 'progress'; // Прогресс вне таймера ({{ getTranslations['Комфортный сон'] }})
      }

      if (this.deviceInfo.currentPreset) {
        return 'fastCommand'; // Применена быстрая команда
      }

      return 'manual'; // Ручной режим
    },
    isHeater() {
      return this.deviceInfo.deviceType === 'HEATER';
    },
    scheduleIsPassive() {
      return !!(this.deviceInfo.currentSchedule && !this.deviceInfo.state.isOn);
    }
  },
  mounted() {
    this.setStateDetails(this.deviceInfo);
  },
  watch: {
    deviceInfo: {
      deep: true,
      handler(newValue, oldValue) {
        this.setStateDetails(newValue);
      }
    }
  },
  methods: {
    setStateDetails(deviceInfo) {
      const activePeriod = this.deviceInfo.currentSchedule ? this.placeStatus.getActivePeriodState() : null;
      this.stateDetails = activePeriod
        ? { ...activePeriod, state: 'activeShedule'}
        : { ...deviceInfo.state, state: 'common'};
    }
  },
};


import { mapActions, mapGetters } from 'vuex';
import ArrowLeftIcon from "@/assets/icons/36x36/arrowLeft.svg";
import ControlPanelCircular from "@/components/control-panel/ControlPanelCircular.vue";
import ControlPanelSwitch from "@/components/control-panel/ControlPanelSwitch.vue";
import ControlPanelLottie from "@/components/control-panel/ControlPanelLottie.vue";
import ControlPanelIcon from "@/components/control-panel/ControlPanelIcon.vue";
import ControlPanelCard from "@/components/control-panel/ControlPanelCard.vue";
import ControlPanelLinear from "@/components/control-panel/ControlPanelLinear.vue";
import ControlPanelTimer from "@/components/control-panel/ControlPanelTimer.vue";
import ControlPanelSchedule from "@/components/control-panel/ControlPanelSchedule.vue";
import QuestionIcon from '@/assets/icons/24x24/additional-modes/question.svg';

export default {
  name: 'HeaterAdditionalPanel',
  components: {
    ArrowLeftIcon,
    ControlPanelCircular,
    ControlPanelSwitch,
    ControlPanelLottie,
    ControlPanelIcon,
    ControlPanelCard,
    ControlPanelLinear,
    ControlPanelTimer,
    ControlPanelSchedule,
    QuestionIcon,
  },
  props: {},
  data: () => ({
    functionItem: null,
    functions: null,
    isHeater: false,
    cssVars: {},
    currentFunctionId: null,
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
      deviceInfo: 'deviceFunctions/getDeviceInfo',
      getCurrentFunction: 'deviceFunctions/getCurrentFunction',
    }),
    deviceConnected() {
      return this.deviceInfo?.status === "connected";
    }
  },
  beforeMount() {
    this.setData();
  },
  watch: {
    deviceInfo: {
      deep: true,
      handler() {
        this.setData();
      }
    }
  },
  methods: {
    ...mapActions({
      applyDeviceFunction: 'deviceFunctions/applyDeviceFunction',
      toggleAdditionalPanelActive: "system/toggleAdditionalPanelActive",
      setModalData: 'modalAdditionalMode/setModalData',
    }),
    setData() {
      this.isHeater = this.deviceInfo.deviceType === 'HEATER';
      this.getCurrentFunc();
      if (this.functionItem?.metaData?.parameters?.heaterAdditionalSettings?.functions) {
        this.functions = this.functionItem.metaData.parameters.heaterAdditionalSettings.functions;
      }
      if (!this.functionItem.state.blocked || (this.isHeater && this.deviceInfo.state.isOn)) {
        this.cssVars = {
          '--primary': this.deviceInfo.theme.primary,
          '--background': this.deviceInfo.theme.background,
          '--gradient': `linear-gradient(90deg, ${this.deviceInfo.theme.gradient[0]} 0%, ${this.deviceInfo.theme.gradient[1]} 100%)`
        };
      }
    },
    getCurrentFunc() {
      if (!this.currentFunctionId) {
        this.functionItem = this.getCurrentFunction;
        this.currentFunctionId = this.functionItem.id;
      } else {
        const functionsArray = this.deviceInfo.pult
          .find(funcArray => funcArray.functions.find(func => func.id === this.currentFunctionId));
        const current = functionsArray.functions.filter(func => func.id === this.currentFunctionId).pop();
        if (current) {
          this.functionItem = current;
        }
      }
    },
    hideAdditionalPanel() {
      this.toggleAdditionalPanelActive(false);
    },
    getFunctionDescription(id) {
      return this.$axios.get(
        `functions/${id}/description`,
        {
          headers: {
            Authorization: `Bearer ${this.$auth.user.token}`,
          },
        },
      )
        .then((response) => response.data.data)
        .catch((error) => {
          errorServiceHandler.call(this.$store, error);
        });
    },
    async openClueModal(id) {
      const functionDescription = await this.getFunctionDescription(id);
      if (!functionDescription) return;

      this.setModalData({
        iconSvg: functionDescription.iconSvg || functionDescription.icon,
        title: functionDescription.fullTitle || functionDescription.title,
        text: functionDescription.description,
      });

      this.$store.$modal.show('additionalModelTip');
    },
    toggleControlItemContextMenu(e) {
      this.$refs.controlItemContextMenu.toggle(e);
    },
  }
}

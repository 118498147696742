
import Calendar from 'assets/icons/calendar.svg';
import CalendarSmall from 'assets/icons/14x14/schedule_new.svg';
import TimeIcon from '../../../assets/icons/time2.svg';
import PlaceStatus from '../../class/PlaceStatus';
import ShowCurrentState from '@/components/small/ShowCurrentState';
import ShowStateDetails from '@/components/small/ShowStateDetails';
import AppCheckbox from '../../checks/AppCheckbox.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppCheckbox, TimeIcon, Calendar, CalendarSmall, ShowCurrentState, ShowStateDetails
  },
  props: {
    place: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    stateDetails: {},
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    placeStatus() {
      return new PlaceStatus(this.place);
    },
    countShowIcons() {
      return this.size === 'small' ? 3 : 4;
    },
    weekDayName() {
      return ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']]
    },
    isHeater() {
      return this.place.deviceType === 'HEATER';
    },
    isHumidifierOrCleaner() {
      return this.place.deviceType === 'HUMIDIFIER' || this.place.deviceType === 'AIR_CLEANER';
    }
  },
  mounted() {
    // console.log('ConnectedCardRoomState mounted', this.place.state.details);
    this.stateDetails = this.place.state.details;
  },
  watch: {
    place: {
      deep: true,
      handler(newValue, oldValue) {
        // console.log('ConnectedCardRoomState watch', newValue.state.details);
        this.stateDetails = newValue.state.details;
      }
    }
  },
  methods: {
    startTime(mins) {
      const hours = Math.trunc(mins / 60);
      let minutes = String(mins % 60);
      minutes.length === 1 ? minutes = `0${minutes}` : minutes = minutes;
      return `${hours}:${minutes}`;
    },
    endTime() {
      const listData = this.placeStatus.scheduleCurrentPeriodTimes();
      let end;
      listData.forEach((item, index) => {
        if (item.isActive) {
          end = listData[index + 1];
        }
      });
      return this.startTime(end ? end.start : 0); // 1439 = 23:59
    },
  },
};

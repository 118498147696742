import * as scheme1 from './scheme-1.json';
import * as scheme2 from './scheme-2.json';
import * as scheme3 from './scheme-3.json';
import * as scheme4 from './scheme-4.json';
import * as scheme5 from './scheme-5.json';

export default [
  {
    title: 'SCHEME_1',
    data: scheme1.default,
    text: 'Отличное качество',
    background: 'linear-gradient(90deg, #5EA9FF 0%, rgba(94, 169, 255, 0.5) 100%)',
  },
  {
    title: 'SCHEME_2',
    data: scheme2.default,
    text: 'Хорошее качество',
    background: 'linear-gradient(90deg, #19D9B7 0%, #FFA875 100%)',
  },
  {
    title: 'SCHEME_3',
    data: scheme3.default,
    text: 'Удовлетворительно',
    background: 'linear-gradient(90deg, #859BFF 10.49%, #FFBAAD 104.24%)',
  },
  {
    title: 'SCHEME_4',
    data: scheme4.default,
    text: 'Воздух загрязнён',
    background: 'linear-gradient(90deg, #FF694D 0%, #FFC2A0 100%)',
  },
  {
    title: 'SCHEME_5',
    data: scheme5.default,
    text: 'Высокое загрязнение',
    background: 'linear-gradient(90deg, #FC4242 0%, rgba(252, 66, 66, 0.5) 80.73%)',
  },
];

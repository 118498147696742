export const declensionOfNumbers = (number, words) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ]; // declensionOfNumbers(5, ['минута', 'минуты', 'минут']);

export const getSignDirection = (number) => {
  if (Math.sign(number) === 1) {
    return "+";
  }
  if (Math.sign(number) === -1) {
    return "-";
  }
  return "";
};

export const isTablet = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  );

export const secondsToHHMM = (seconds) => {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  let formattedHours = h;
  let formattedMinutes = m;

  if (h < 10) formattedHours = `0${h.toString()}`;
  if (m < 10) formattedMinutes = `0${m.toString()}`;

  return `${formattedHours}:${formattedMinutes}`;
};

export const throttle = (f, t) =>
  function (args) {
    const previousCall = this.lastCall;

    this.lastCall = Date.now();
    if (
      previousCall === undefined || // function is being called for the first time
      this.lastCall - previousCall > t
    ) {
      // throttle time has elapsed
      f(args);
    }
  };

export const debounce = (fn, ms) => {
  let timeout;
  return function () {
    const fnCall = () => fn.apply(this, arguments);
    clearTimeout(timeout);
    timeout = setTimeout(fnCall, ms);
  };
};

export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getTextStyle = (attributedText) => {
  if (!attributedText) return;
  const styleTag = {};
  const textStyle = attributedText.textStyle?.toLowerCase();

  if (textStyle) {
    // TextStyleEnum: BOLD, MEDIUM, REGULAR
    if (textStyle === "regular") {
      styleTag["font-weight"] = "normal";
    } else if (textStyle === "medium") {
      styleTag["font-weight"] = "500";
    } else if (textStyle === "bold") {
      styleTag["font-weight"] = "600";
    }
  }

  if (attributedText.textColor) {
    styleTag.color = attributedText.textColor;
  }

  return styleTag;
};

export const arrayDifference = (a1, a2) =>
  a1
    .filter((i) => a2.indexOf(i) < 0)
    .concat(a2.filter((i) => a1.indexOf(i) < 0));

export const startTime = (minutes) => {
  const hours = Math.trunc(minutes / 60);
  const minutesRemainder = String(minutes % 60);
  const minutesToShow =
    minutesRemainder.length === 1 ? `0${minutesRemainder}` : minutesRemainder;
  return `${String(hours).length > 1 ? hours : `0${hours}`}:${minutesToShow}`;
};

export const endTime = (startTimeFunction, end) =>
  startTimeFunction(end ? end.start : 0); // 1439 = 23:59

export const pultStateToFunctionConverter = (state) => {
  const functions = [];
  state.forEach((item) => {
    item.functions.forEach((func) => {
      const funcToReturn = {};
      funcToReturn.isOn = func.state.isOn;
      funcToReturn.functionId = func.id;
      funcToReturn.value = func.state.value;
      funcToReturn.parameters = func.metaData.parameters;
      functions.push(funcToReturn);

      if (func.linkedFunction) {
        const funcToReturnLinked = {};
        funcToReturnLinked.isOn = func.linkedFunction.state.isOn;
        funcToReturnLinked.functionId = func.linkedFunction.id;
        funcToReturnLinked.value = func.linkedFunction.state.value;
        funcToReturnLinked.parameters = func.linkedFunction.metaData.parameters;
        functions.push(funcToReturnLinked);
      }
    });
  });
  return functions;
};

export const numberWithSpaces = (x) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const getPrettyDate = (data) => {
  let date = data.split(' ')[0];
  let time = data.split(' ')[1];
  const arr = date.split('-');
  arr[0] = arr[0].slice(-2);
  date = arr.reverse().join('.');
  time = time.slice(0, -3);
  return `${date}, ${time}`;
};

export const translit = (value) => {
  let result = '';
	const converter = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',
 
		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya',

    ' ': '_', ',': ''
	};
 
	for (let i = 0; i < value.length; ++i ) {
		if (converter[value[i]] === undefined){
			result += value[i];
		} else {
			result += converter[value[i]];
		}
	}

  return result;
}



// eslint-disable-next-line import/no-unresolved
import CalendarSmall from '@/assets/icons/14x14/schedule_new.svg';
import PlaceStatus from '@/components/class/PlaceStatus';
import ShowCurrentState from '@/components/small/ShowCurrentState';
import ShowStateDetails from '@/components/small/ShowStateDetails';
import TimeIcon from '@/assets/icons/14x14/timer_new.svg';
import { mapGetters } from 'vuex';

export default {
  components: {
    TimeIcon, CalendarSmall, ShowCurrentState, ShowStateDetails
  },
  props: {
    place: Object,
  },
  data: () => ({
    //weekDayName: ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']],
  }),
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    placeStatus() {
      return new PlaceStatus(this.place);
    },
    weekDayName() {
      return ['', this.getTranslations['Пн'], this.getTranslations['Вт'], this.getTranslations['Ср'], this.getTranslations['Чт'], this.getTranslations['Пт'], this.getTranslations['Сб'], this.getTranslations['Вс']];
    },
    isHeater() {
      return this.place.deviceType === 'HEATER';
    },
    isHumidifierOrCleaner() {
      return this.place.deviceType === 'HUMIDIFIER' || this.place.deviceType === 'AIR_CLEANER';
    },
    isSchedulePostponed() {
      return (this.placeStatus.isScheduleShow() && !this.placeStatus.isScheduleActive());
    }
  },
  methods: {
    startTime(mins) {
      const hours = Math.trunc(mins / 60);
      let minutes = String(mins % 60);
      if (minutes.length === 1) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}`;
    },
    endTime() {
      const listData = this.placeStatus.scheduleCurrentPeriodTimes();
      let end;
      listData.forEach((item, index) => {
        if (item.isActive) {
          end = listData[index + 1];
        }
      });
      return this.startTime(end ? end.start : 0); // 1439 = 23:59
    },
  },
};

import {
  SET_GO_TO_APP_MODAl_TYPE,
  SET_OBJECTS_VIEW,
  SET_SCREEN_WIDTH,
  TOGGLE_CONTROL_PANEL,
  TOGGLE_ADDITIONAL_PANEL,
  TOGGLE_DND_ACTIVE,
  TOGGLE_FAST_COMMAND_PANEL,
  TOGGLE_FAST_COMMAND_PANEL_TYPE,
  TOGGLE_OBJECTS_PANEL,
  TOGGLE_SHOW_MODAL,
  CONTROL_PANEL_DEVICE_ID,
  CONTROL_PANEL_GROUP,
  SET_PANEL_FROM_GROUP,
  SET_MODAL_SHOW_COUNT,
  FORCE_LOCAL_LOADING_CARD_PLACE_ID,
  SET_VERSIONS,
  SET_SCHEDULE_ACTIVE_ID,
  TOGGLE_SCHEDULE_PANEL,
  SET_CAPTCHA_VISIBILITY,
  SET_CAPTCHA_CALLBACK,
  SET_CAPTCHA_CANCEL_CALLBACK,
  SET_CAPTCHA_HEADER
} from '@/utils/types';

import { APP_VERSION } from '@/versions/app-version';

export const state = () => ({
  objectsPanelActive: null,
  additionalPanelActive: false,
  panelActive: null,
  panelActiveFromGroup: false,
  panelDeviceId: null,
  panelGroupId: null,
  showModal: false,
  screenWidth: null,
  objectsView: localStorage.getItem('viewType') || 'cards',
  goToAppModalType: 'auth',
  dndActive: false,
  fastCommandsPanel: localStorage.getItem('presetInPanelInfoId') && document.location.pathname === '/fast-commands',
  fastCommandsPanelType: localStorage.getItem('fastCommandsPanelType'),
  showModalCount: 0,
  forceLocalLoadingCardPlaceId: null,
  frontVersion: null,
  backVersion: null,
  scheduleMenuActive: false,
  scheduleActiveId: null,
  isCaptchaVisible: false,
  captchaCallback: () => {},
  captchaCancelCallback: null,
  captchaHeader: null
});

export const getters = {
  getVersions: (e) => ({ front: e.frontVersion, back: e.backVersion }),
  getPanelActiveFromGroup: ({ panelActiveFromGroup }) => panelActiveFromGroup,
  getShowModal: ({ showModal }) => showModal,
  getScreenWidth: ({ screenWidth }) => screenWidth,
  getPanelState: ({ panelActive }) => panelActive,
  getPanelDeviceId: ({ panelDeviceId }) => panelDeviceId,
  getPanelGroupId: ({ panelGroupId }) => panelGroupId,
  getObjectsView: ({ objectsView }) => objectsView,
  getGoToAppModalType: ({ goToAppModalType }) => goToAppModalType,
  getObjectsPanelActive: ({ objectsPanelActive }) => objectsPanelActive,
  getAdditionalPanelActive: ({ additionalPanelActive }) => additionalPanelActive,
  getDndActive: ({ dndActive }) => dndActive,
  getFastCommandsPanel: ({ fastCommandsPanel }) => fastCommandsPanel,
  getFastCommandsPanelType: ({ fastCommandsPanelType }) => fastCommandsPanelType,
  getModalShowCount: ({ showModalCount }) => showModalCount,
  getForceLocalLoadingCardPlaceId: ({ forceLocalLoadingCardPlaceId }) => forceLocalLoadingCardPlaceId,
  getScheduleMenuActive: ({ scheduleMenuActive }) => scheduleMenuActive,
  getScheduleActiveId: ({ scheduleActiveId }) => scheduleActiveId,
  getCaptchaVisibility: ({ isCaptchaVisible }) => isCaptchaVisible,
  getCaptchaCallback: ({ captchaCallback }) => captchaCallback,
  getCaptchaCancelCallback: ({ captchaCancelCallback }) => captchaCancelCallback,
  getCaptchaHeader: ({ captchaHeader }) => captchaHeader,
};

// typeof arguments[1] === 'undefined' - тут делаем проверку передан ли аргумент в функцию
// если нет , то переключается , а если передан то присваиваем переданное значение

export const mutations = {
  [SET_VERSIONS](state, versions) {
    state.frontVersion = versions.front;
    state.backVersion = versions.back;
  },
  [SET_PANEL_FROM_GROUP](state, value) {
    state.panelActiveFromGroup = value;
  },
  [TOGGLE_FAST_COMMAND_PANEL_TYPE](state, type) {
    state.fastCommandsPanelType = type;
    localStorage.setItem('fastCommandsPanelType', type);
  },
  [TOGGLE_FAST_COMMAND_PANEL](state, value) {
    state.fastCommandsPanel = value;
    if (value === false) {
      localStorage.removeItem('presetInPanelName');
      localStorage.removeItem('presetInPanelInfoId');
      localStorage.removeItem('fastCommandsPanelType');
    }
  },
  [TOGGLE_CONTROL_PANEL](state, value) {
    if (state.additionalPanelActive) {
      state.additionalPanelActive = false;
    }
    if (!state.showModal) {
      if (typeof arguments[1] === 'undefined') {
        state.panelActive = !state.panelActive;
      } else {
        state.panelActive = value;
      }
    }
  },
  [TOGGLE_ADDITIONAL_PANEL](state, value) {
    state.additionalPanelActive = value;
  },
  [CONTROL_PANEL_DEVICE_ID](state, value) {
    state.panelDeviceId = value;
  },
  [CONTROL_PANEL_GROUP](state, id) {
    state.panelGroupId = id;
  },
  [TOGGLE_DND_ACTIVE](state, value) {
    state.dndActive = value;
  },
  [TOGGLE_SHOW_MODAL](state, value) {
    if (typeof arguments[1] === 'undefined') {
      state.showModal = !state.showModal;
    } else {
      state.showModal = value;
    }
  },
  [SET_MODAL_SHOW_COUNT](state, value) {
    if (value) {
      state.showModalCount++;
    } else {
      state.showModalCount--;
    }
  },
  [SET_SCREEN_WIDTH](state, width) {
    state.screenWidth = width;
  },
  [SET_OBJECTS_VIEW](state, view) {
    state.objectsView = view;
    localStorage.setItem('viewType', view);
  },
  [SET_GO_TO_APP_MODAl_TYPE](state, type) {
    state.goToAppModalType = type;
  },
  [TOGGLE_OBJECTS_PANEL](state, value) {
    if (!state.showModal) {
      state.objectsPanelActive = value;
    }
  },
  [TOGGLE_SCHEDULE_PANEL](state, value) {
    state.scheduleMenuActive = value;
  },
  [SET_SCHEDULE_ACTIVE_ID](state, value) {
    state.scheduleActiveId = value;
  },
  [FORCE_LOCAL_LOADING_CARD_PLACE_ID](state, value) {
    state.forceLocalLoadingCardPlaceId = value;
  },
  [SET_CAPTCHA_VISIBILITY](state, value) {
    state.isCaptchaVisible = value;
  },
  [SET_CAPTCHA_CALLBACK](state, value) {
    state.captchaCallback = value;
  },
  [SET_CAPTCHA_CANCEL_CALLBACK](state, value) {
    state.captchaCancelCallback = value;
  },
  [SET_CAPTCHA_HEADER](state, value) {
    state.captchaHeader = value;
  },
};

export const actions = {
  setAppVersion({ commit }) {
    this.$axios.get('application/info')
      .then((e) => {
        commit('SET_VERSIONS', {
          back: e.data.version,
          front: `v.${APP_VERSION}`,
        });
      })
      .catch(() => {
        commit('SET_VERSIONS', {
          back: '3.3.4',
          front: `v.${APP_VERSION}`,
        });
      });
  },
  setDashboardPadding({ commit }) {
    // commit(SET_DASHBOARD_PADDING, window.dashboardContent?.clientHeight <= window.innerHeight);
  },
  toggleFastCommandsPanelType({ commit }, value) {
    commit(TOGGLE_FAST_COMMAND_PANEL_TYPE, value);
  },
  toggleFastCommandsPanel({ commit }, value) {
    commit(TOGGLE_FAST_COMMAND_PANEL, value);
    if (!value) {
      commit('fastCommandsPage/SET_PRESET_IN_PANEL_INFO', null, { root: true });
    }
  },
  togglePanelFromGroup({ commit }, value) {
    commit(SET_PANEL_FROM_GROUP, value);
  },
  togglePanelActive({ commit, dispatch }, value) {
    commit(TOGGLE_CONTROL_PANEL, value);
    if (!value) {
      commit(SET_PANEL_FROM_GROUP, false);
      dispatch('setPanelDevice', null);
    }
  },
  toggleAdditionalPanelActive({ commit }, value) {
    commit(TOGGLE_ADDITIONAL_PANEL, value);
  },
  setPanelDevice({ commit }, value) {
    commit(CONTROL_PANEL_DEVICE_ID, value);
    if (value) {
      localStorage.setItem('controlPanelId', value);
    } else {
      delete localStorage.controlPanelId;
    }
  },
  setPanelGroup({ commit }, groupId) {
    commit(CONTROL_PANEL_GROUP, groupId);
  },
  setShowModal({ commit, state }, value) {
    if (value) {
      document.body.style.overflow = 'hidden';
    } else if (state.panelActive && window.innerWidth <= 1440) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    commit(TOGGLE_SHOW_MODAL, value);
    commit(SET_MODAL_SHOW_COUNT, value);
  },
  setScreenWidth({ commit }, width) {
    commit(SET_SCREEN_WIDTH, width);
  },
  setObjectsView({ commit }, view) {
    commit(SET_OBJECTS_VIEW, view);
  },
  setGoToAppModalType({ commit }, type) {
    commit(SET_GO_TO_APP_MODAl_TYPE, type);
  },
  setObjectPanel({ commit, getters }, value) {
    commit(TOGGLE_OBJECTS_PANEL, value);
    if (!value && !getters.getPanelActiveFromGroup) {
      commit(CONTROL_PANEL_GROUP, null);
    }
  },
  setDndActive({ commit }, value) {
    commit(TOGGLE_DND_ACTIVE, value);
  },
};

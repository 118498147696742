
export default {
  name: 'ShowStateIcons',
  props: {
    detailsState: {
      type: Object,
      required: true
    },
    imgClass: {
      type: String,
      default: 'icon-14'
    },
    textClass: {
      type: String,
      default: 'state-text'
    }
  }
}

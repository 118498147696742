
import { mapGetters } from 'vuex';

export default {
  name: 'InputRange',
  props: {
    value: {
      type: Number,
      default: 26,
    },
    max: {
      type: Number,
      default: 40,
    },
    min: {
      type: Number,
      default: 12,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    deviceIsOn: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    // bgGradient: '',
  }),
  computed: {
    ...mapGetters({
      currentGradient: 'deviceFunctions/getCurrentGradient',
    }),
    // тут крч магия чтоб у нас градиент был до ползунка, не трогать
    gradientValue() {
      return 100 - (this.lineValue / this.lengthOfLine * 100);
    },
    lengthOfLine() {
      return this.max - this.min;
    },
    lineValue() {
      return this.value - this.min;
    },
    bgGradient() {
    // Обходим глюк с отсутствием заполнения у ползунка на доп.окне
      return (this.currentGradient && !this.disabled) ? `.input-range input::-webkit-slider-thumb {background:${this.currentGradient}!important};.input-range input::-moz-range-thumb {background:${this.currentGradient}!important}` : '';
    }
  },
  methods: {
    changeInput(e) {
      this.$emit('valueChange', Number(e.target.value));
    },
  },
};


import WifiDisconnectIcon from '../../../assets/icons/disconnect16.svg';
import WifiDisconnectIcon36 from '../../../assets/icons/disconnect36.svg';
import { mapGetters } from 'vuex';

export default {
  components: { WifiDisconnectIcon, WifiDisconnectIcon36 },
  props: {
    place: Object,
  },
  computed: {
    ...mapGetters({
      getTranslations: 'localization/getTranslations',
    }),
    isHumidifierOrCleaner() {
      return this.place.deviceType === 'HUMIDIFIER' || this.place.deviceType === 'AIR_CLEANER';
    },
  }
};
